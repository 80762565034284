import ABI from '../abi.json';
import Contract from './contract';
import { store } from 'redux/config';
import { MaxUint256 } from '@ethersproject/constants';
import { ETHEREUM_TOKEN, ZERO_ADDRESS } from 'utils/variables';
import { addTx, completedTx } from 'redux/actions/transactions';
import Web3 from 'web3';
import { ethers } from 'ethers';

const dispatch = store.dispatch;

class Staking extends Contract {
    async getInfo() {
        const [minStakeAmount, interestFields, maxDays, active] = await Promise.all([
            this.contracts.StakeMinter.minimumStakeAmount(),
            this.contracts.StakeManager.getInterestFields(),
            this.contracts.StakeUpgrader.getMaxShareMaxDays(),
            this.contracts.StakeUpgrader.getMaxShareEventActive(),
        ]);

        return {
            shareRate: interestFields.shareRate,
            maxShare: { active, maxDays: parseInt(maxDays) },
            minStakeAmount: +minStakeAmount.formatUnits(),
        };
    }

    /**
     * Create a new Axion stake.
     *
     * @param {string|number} amount - Amount of axion to stake
     * @param {string|number} days - # of days to stake
     */
    async stake(amount, days) {
        const tx = await this.contracts.StakeMinter.connect(this.signer).stake(amount, days);
        dispatch(
            addTx({
                id: tx.hash,
                description: `Stake ${(amount / 1e18).toLocaleString()} AXN for ${(+days).toLocaleString()} days`,
            }),
        );
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Withdraw a stake.
     *
     * @param {string|number} stakeID - The sessionID of the stake to withdraw
     */
    async withdraw(stakeID) {
        let tx = await this.contracts.StakeBurner.connect(this.signer).burnStake(stakeID);
        dispatch(addTx({ id: tx.hash, description: `Withdraw Stake` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Convert a stake to a NFT.
     *
     * @param {string|number} stakeID - The sessionID of the stake to convert
     * @param {string|number} cost - The cost in MATIC to convert the stake
     */
    async convertToNFT(stakeID, cost) {
        let tx = await this.contracts.StakeUtilities.connect(this.signer).convertToNft(stakeID, {
            value: ethers.utils.parseEther(`${cost}`).toString(),
        });
        dispatch(addTx({ id: tx.hash, description: `Mint Stake NFT` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Restake a matured stake. Optionally include additional AXN
     * as a topup to the stake,
     *
     * @param {object} stake - The stake object
     * @param {string|number} stakeDays - The amount of days for the restake
     * @param {string|number} topUp - The topup amount, or 0 if there is none
     */
    async restake(stake, stakeDays, topUp) {
        let tx = await this.contracts.StakeReminter.connect(this.signer).remintStake(stake.id, stakeDays, topUp);
        dispatch(addTx({ id: tx.hash, description: 'Restake Matured Stake' }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Upgrades a stake to max length (5,555 days) and adds any accumulated
     * interest to the principal.
     *
     * @param {string|number} stakeID - The sessionID of the stake to upgrade
     */
    async upgrade(stakeID) {
        let tx = await this.contracts.StakeUpgrader.connect(this.signer).maxShareUpgrade(stakeID);
        dispatch(addTx({ id: tx.hash, description: 'Upgrade Stake' }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Approve AXN spending for the Staking contract
     */
    async approve() {
        let tx = await this.contracts.AXNContract.connect(this.signer).approve(contracts.StakingContract.address, MaxUint256);
        dispatch(addTx({ id: tx.hash, description: 'Stake Approval' }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Withdraws the selected div token from liquid rewards.
     *
     * @param {string} address - The address of the token to withdraw
     */
    async withdrawDivForToken(address) {
        let tx = await this.contracts.VC.connect(this.signer).withdrawDivTokens(address);
        dispatch(addTx({ id: tx.hash, description: 'Withdraw Rewards' }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Withdraws the selected div token from liquid rewards
     * to the provided address. (NOTE: Not currently in use)
     *
     * @param {string} address - The address of the token to withdraw
     * @param {string} to - The address to send the divs to
     */
    async withdrawDivForTokenTo(address, to) {
        let tx = this.contracts.VC.connect(this.signer).withdrawDivTokensTo(to, address);
        dispatch(addTx({ id: tx.hash, description: 'Withdraw Rewards External' }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    getVentureAuctionTokens() {
        return this.contracts.VC.getDivTokens();
    }

    getVentureAuctionInterestEarned(tokenAddress) {
        return this.contracts.VC.getTokenInterestEarned(this.account, tokenAddress);
    }

    async getVentureAuctionTokenInfo(tokenAddress) {
        const tokenContract = this.getContract(ABI.ERC20.ABI, tokenAddress);
        const tokenName = await tokenContract.name();
        const tokenSymbol = await tokenContract.symbol();
        const tokenDecimals = +(await tokenContract.decimals());

        return {
            tokenName,
            tokenSymbol,
            tokenDecimals,
        };
    }

    async getVentureAuctionDivs() {
        const vcaTokens = await this.getVentureAuctionTokens();
        const excludedTokens = [ZERO_ADDRESS, ABI.mainnet.Tokens.USDC?.toLowerCase() || ZERO_ADDRESS];
        const vcaDivs = [];

        if (vcaTokens) {
            for (const tokenAddress of vcaTokens.filter((x) => !excludedTokens.includes(x.toLowerCase()))) {
                // Get interest
                const interest = (await this.getVentureAuctionInterestEarned(tokenAddress)).toString();
                let interestEarnedUSDC = 0;
                if (interest !== '0') {
                    try {
                        interestEarnedUSDC = await this.getTokenToUsdcAmountsOutAsync(tokenAddress, interest);
                    } catch (error) {
                        console.log('Cannot get USD value for interest', error);
                    }
                }

                // Check if Ethereum
                if (tokenAddress === ETHEREUM_TOKEN.tokenAddress) {
                    vcaDivs.push({
                        ...ETHEREUM_TOKEN,
                        interestEarnedToken: interest / 1e18,
                        interestEarnedUSDC: interestEarnedUSDC / 1e6,
                    });
                } else {
                    const { tokenName, tokenSymbol, tokenDecimals } = await this.getVentureAuctionTokenInfo(tokenAddress);
                    vcaDivs.push({
                        tokenName,
                        tokenSymbol,
                        tokenAddress,
                        tokenDecimals,
                        interestEarnedUSDC: interestEarnedUSDC / 1e6,
                        interestEarnedToken: interest / 10 ** tokenDecimals,
                    });
                }
            }
        }

        return vcaDivs;
    }

    getStakePayoutAndPenalty(stake) {
        console.log(stake.principal, stake.interest);
        const principal = Web3.utils.toWei(`${stake.principal}`);
        const interest = Web3.utils.toWei(`${stake.interest}`);
        return this.contracts.StakeBurner.getPayoutAndPenalty(principal, stake.startSeconds, stake.stakeDays, interest);
    }
}

export default Staking;
