import React from 'react';
// Modules
import { connect } from 'react-redux';
import { pop as popNotification, shift as shiftNotification, reset as resetNotifications } from 'redux/actions/notifications';

import { CONTRACT_INFO, SECONDS_IN_DAY } from 'utils/variables';
import BN from 'bn.js';
import web3 from 'web3';
// Components
import Card from 'components/Card';
import { Typography, Button, LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ReactComponent as Crown } from 'assets/images/crown.svg';
import { ReactComponent as Fire } from 'assets/images/fire.svg';
import Bitcoin from 'assets/images/Bitcoin-Logo.png';
import AXN from 'assets/images/axion-logo.png';
// styles
import styles from './styles';
import withRouter from 'hooks/withRouter';

const COUNTDOWN_SECONDS = 15;
const WBTC_1E10 = new BN('10000000000');
const AXN_1E18 = new BN('1000000000000000000');
const SHOWN_NOTIF_TYPES = ['StakeCreated', 'StakeDeleted', 'StakeUpgraded', 'WithdrawLiquidDiv', 'AcceleratorEth', 'AcceleratorToken'];

class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timeLeft: COUNTDOWN_SECONDS,
        };
    }

    componentDidMount() {
        if (this.props.notifications.length > 0) {
            this.setTimer();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname.includes('/activity') && this.props.notifications.length > 0) {
            this.handleClose();
        } else if (prevProps.notifications.length !== this.props.notifications.length) {
            if (!this.timer && this.props.notifications.length > 0) {
                this.setState(
                    {
                        timeLeft: COUNTDOWN_SECONDS,
                    },
                    () => {
                        this.setTimer();
                    },
                );
            }
        } else if (!this.timer && this.props.notifications.length > 0) {
            this.setTimer();
        }
    }

    componentWillUnmount() {
        this.timer = null;
        clearInterval(this.timer);
    }

    setTimer() {
        if (this.timer) clearInterval(this.timer);
        const { shiftNotification } = this.props;

        this.timer = setInterval(() => {
            /** Clear interval, pop notification, and empty timer */
            if (this.state.timeLeft <= 0) {
                clearInterval(this.timer);
                this.timer = null;
                return shiftNotification();
            }

            /** Countdown */
            this.setState({
                timeLeft: this.state.timeLeft - 0.2,
            });
        }, 200);
    }

    handleClose() {
        clearInterval(this.timer);
        this.timer = null;
        this.props.resetNotifications();
    }

    handleNext() {
        this.setState({
            timeLeft: 0,
        });
    }

    renderBody(notification) {
        if (notification.type === 'StakeCreated') {
            const stakeDays = (parseInt(notification.end) - parseInt(notification.start)) / SECONDS_IN_DAY;
            return `A ${notification.ecoBefore} has staked ${(notification?.amount / 1e18)?.shorten(
                2,
            )} AXN for ${stakeDays?.numberWithCommas(0)} days!`;
        } else if (notification.type === 'StakeDeleted') {
            const stakeDays = (parseInt(notification.end) - parseInt(notification.start)) / SECONDS_IN_DAY;
            return `A ${notification.ecoBefore} has unstaked ${(notification?.payout / 1e18)?.shorten(
                2,
            )} AXN after ${stakeDays?.numberWithCommas(0)} days.`;
        } else if (notification.type === 'StakeUpgraded') {
            return `A ${notification.ecoBefore} has upgraded their ${(notification?.newAmount / 1e18)?.shorten(2)} AXN stake to max shares`;
        } else if (notification.type === 'WithdrawLiquidDiv' && notification.tokenAddress === CONTRACT_INFO.Tokens.WBTC) {
            const interest = parseFloat(web3.utils.fromWei(new BN(notification.interest ?? '0').mul(WBTC_1E10)));
            return `A ${notification.ecoBefore} has withdrawn ${interest} BTC!`;
        } else if (notification.type === 'WithdrawLiquidDiv' && notification.tokenAddress === CONTRACT_INFO.AXN.ADDRESS) {
            const interest = parseFloat(notification.interest / 1e18).toFixed(2);
            return `A ${notification.ecoBefore} has withdrawn ${interest} AXN!`;
        } else if (notification.type === 'AcceleratorEth' || notification.type === 'AcceleratorToken') {
            const burned = parseFloat(web3.utils.fromWei(notification.axionBought ?? '0')); // "5743034.602988242227197480"
            const staker = parseFloat(web3.utils.fromWei(new BN(notification.tokenBought ?? '0').mul(WBTC_1E10)));

            return `${burned.shorten(1)} AXN burned and ${staker} BTC generated!`;
        }
    }

    getEventName(notification) {
        if (notification.type === 'StakeCreated') {
            return 'Stake Created';
        } else if (notification.type === 'StakeDeleted') {
            return 'Stake has Ended';
        } else if (notification.type === 'StakeUpgraded') {
            return 'Stake Upgraded';
        } else if (notification.type === 'WithdrawLiquidDiv') {
            return 'Liquid Divs Withdrawn';
        } else if (notification.type === 'AcceleratorEth' || notification.type === 'AcceleratorToken') {
            return 'New Accelerator Purchase';
        }
    }

    render() {
        const { classes, notifications } = this.props;
        const { timeLeft } = this.state;

        const notification = notifications[0];

        if (!notification) return <div style={{ display: 'none' }} />;
        if (!SHOWN_NOTIF_TYPES.includes(notification.type)) return <div style={{ display: 'none' }} />;

        const isMax = (parseInt(notification.end) - parseInt(notification.start)) / SECONDS_IN_DAY === 5555;
        const isBTCIcon =
            (notification.type === 'WithdrawLiquidDiv' && notification.tokenAddress === CONTRACT_INFO.Tokens.WBTC) ||
            notification.type === 'AcceleratorEth' ||
            notification.type === 'AcceleratorToken';

        return (
            <Card classes={{ root: classes.root }}>
                <div className={classes.cardTitle}>
                    {notification.type === 'StakeUpgraded' && <Fire className={classes.crown} />}
                    {notification.type === 'StakeCreated' && isMax && <Crown className={classes.crown} />}
                    {isBTCIcon ? (
                        <img src={Bitcoin} alt="btc" className={classes.btc} />
                    ) : (
                        <img src={AXN} alt="axion" className={classes.axn} />
                    )}
                    <Typography variant="h5">{this.getEventName(notification)}</Typography>
                </div>
                <div className={classes.cardBody}>
                    <p className={classes.body}>{this.renderBody(notification)}</p>
                    {notifications.length > 1 ? (
                        <p className={classes.note}>There are {notifications.length - 1} other new events</p>
                    ) : (
                        <p className={classes.note}>There are no more notifications</p>
                    )}
                </div>

                <div className={classes.cardFooter}>
                    {notifications.length > 1 && (
                        <Button color="primary" onClick={this.handleNext.bind(this)}>
                            Next
                        </Button>
                    )}
                    <Button color="primary" onClick={this.handleClose.bind(this)}>
                        Close
                    </Button>
                </div>
                <LinearProgress variant="determinate" value={(timeLeft / 15) * 100} className={classes.progress} />
                <div className={classes.poweredDiv}>
                    <a className={classes.poweredBy} href="https://axion.today/activity" target="_blank" rel="noreferrer">
                        Powered by <span className={classes.at}>Axion Today</span>
                    </a>
                </div>
            </Card>
        );
    }
}

const mapStateToProps = ({ notifications }) => ({ notifications });

export default connect(mapStateToProps, {
    resetNotifications,
    shiftNotification,
    popNotification,
})(withStyles(styles)(withRouter(Notification)));
