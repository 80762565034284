/** Basic react stuff */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import reportWebVitals from './reportWebVitals';
import 'utils/string';
// Material UI & Redux
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/config';
import Router from './Router';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/styles';
import { createTheme, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';

/** Theme */
import WagmiListener from 'web3/WagmiListener';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Web3ModalWrapper } from 'web3/Web3ModalWrapper';

const ThemeConnectedApp = () => {
    const { theme } = useSelector(({ theme }) => ({ theme }));
    const _theme = createTheme(theme);

    return (
        <MaterialThemeProvider theme={_theme}>
            <ThemeProvider theme={_theme}>
                <Web3ModalWrapper>
                    <WagmiListener>
                        <Router />
                    </WagmiListener>
                </Web3ModalWrapper>
            </ThemeProvider>
        </MaterialThemeProvider>
    );
};

const App = (props) => {
    return (
        <Provider store={store}>
            <GoogleReCaptchaProvider reCaptchaKey="6LcF3IQcAAAAAJw6LIIqaqcsEXGbinMj0-ZFFfBR">
                <PersistGate loading={<CircularProgress size={150} />} persistor={persistor}>
                    <ThemeConnectedApp />
                </PersistGate>
            </GoogleReCaptchaProvider>
        </Provider>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
