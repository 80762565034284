import React, { createContext, useEffect, useRef, useState } from 'react';
import { useAccount, useWalletClient } from 'wagmi';
import BlockChain from './Blockchain';
import { useDispatch } from 'react-redux';
import { setChain } from 'redux/actions/chain';
import { INFURA_URL, MATIC_CHAIN, POLYGON_RPC, ZERO_ADDRESS } from 'utils/variables';
import Web3 from 'web3';
import { setAddress, setKYC } from 'redux/actions/user';
import { getKYCStatus } from 'utils/get-kyc';
import { ethers } from 'ethers';

export const ContractContext = createContext();

const clientToProviderSigner = async (client) => {
    const { account, chain, transport } = client;
    const network = {
        chainId: chain?.id,
        name: chain?.name,
        ensAddress: chain?.contracts?.ensRegistry?.address,
    };
    // You can use whatever provider that fits your need here.
    const provider = new ethers.providers.Web3Provider(transport, network);
    const signer = await provider.getSigner(account?.address);
    return { provider, signer };
};

const WagmiListener = (props) => {
    const { address, isConnected, chain } = useAccount();

    const { data: walletClient } = useWalletClient();
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);

    const initializing = useRef();
    const [user, setUser] = useState(null);
    const [initialized, setInitialized] = useState(null);
    const [userLoading, setUserLoading] = useState(false);
    const [contractInfo, setContractInfo] = useState(null);
    const [loadingContractInfo, setLoadingContractInfo] = useState(true);
    const [web3, setWeb3] = useState(null);
    const [ethWeb3, setEthWeb3] = useState(null);
    const networking = useRef({});
    const kycInterval = React.useRef(null);
    const refreshInterval = React.useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        isConnected && walletClient && connectWallet();
    }, [isConnected, walletClient]);

    async function connectWallet() {
        if (!walletClient) return;
        const { signer, provider } = await clientToProviderSigner(walletClient);
        setSigner(signer);
        setProvider(provider);
    }

    useEffect(() => {
        if (isConnected && chain && signer && provider) {
            initialize();
        } else if (!isConnected) {
            cheapSetup();
        }
    }, [isConnected, chain, address, signer]);

    function cheapSetup() {
        if (initializing.current) return;
        initializing.current = true;
        if (initialized) return;
        const provider = new ethers.providers.JsonRpcProvider(POLYGON_RPC);
        BlockChain.initialize({ address: ZERO_ADDRESS, chain: 'Polygon', provider, signer });
        getContractData(true);
        setInitialized('cheap-setup');
        initializing.current = false;
    }

    function setup() {
        // Setup ETH web3 instance
        const ethProvider = new Web3.providers.HttpProvider(INFURA_URL);
        const _ethWeb3 = new Web3(ethProvider);

        const _web3 = new Web3(new Web3.providers.HttpProvider(POLYGON_RPC));

        // Set Data
        setWeb3(_web3);
        setEthWeb3(_ethWeb3);
    }

    async function _checkKYC() {
        const kyc = process.env.NODE_ENV === 'development' ? false : await getKYCStatus(address);
        if (kyc) dispatch(setKYC(kyc));
        return kyc;
    }

    async function setupPolling() {
        if (refreshInterval.current) {
            clearInterval(refreshInterval.current);
            refreshInterval.current = null;
        }

        if (kycInterval.current) {
            clearInterval(kycInterval.current);
            kycInterval.current = null;
        }

        const kyc = await _checkKYC();
        if (kyc && kyc.status === 'NOT_VERIFIED') {
            kycInterval.current = setInterval(() => {
                _checkKYC().then((kyc) => {
                    if (kyc.status === 'VERIFIED') {
                        clearInterval(kycInterval.current);
                        kycInterval.current = null;
                    }
                });
            }, 30 * (60 * 1000)); // 30 minutes
        }

        refreshInterval.current = setInterval(async () => {
            await getData();
        }, 60 * 1000); // 60 seconds
    }

    async function initialize() {
        /** isConnected and chain? will fire multiple times */
        if (initializing.current) return;
        if (initialized === `${chain?.id}-${address}`) return;
        initializing.current = true;

        setup();
        setupPolling();
        dispatch(setChain(chain?.id));
        dispatch(setAddress(address));
        BlockChain.initialize({ address, provider, chain: chain?.id, signer });
        await getData(true);

        setInitialized(`${chain?.id}-${address}`); // this account for chain change and address change
        initializing.current = false;
    }

    async function getData(setLoad = false) {
        setLoad && setUserLoading(true);
        setLoad && setLoadingContractInfo(true);

        await getContractData(setLoad);
        await getUserData(setLoad);
    }

    async function getUserData(setLoad = false) {
        if (networking.current['userData']) return;
        networking.current['userData'] = true;

        setLoad && !userLoading && setUserLoading(true);
        try {
            let _user = { stakes: [] },
                ventureDivs = [],
                allocations = [];
            if (chain?.id === MATIC_CHAIN) {
                allocations = await BlockChain.Pledge.getUserAllocationPerPledge(_user);
            }

            setUser({ ..._user, ventureDivs, allocations });
        } catch (error) {
            console.log('Error loading user', error);
        }
        setLoad && setUserLoading(false);

        networking.current['userData'] = false;
    }

    async function getContractData(setLoad = false) {
        if (networking.current['contractData']) return;
        networking.current['contractData'] = true;

        setLoad && !loadingContractInfo && setLoadingContractInfo(true);
        try {
            const _contractInfo = await BlockChain.getAllContractDetails();
            setContractInfo(_contractInfo);
        } catch (error) {
            console.log('Error loading contract info', error);
        } finally {
            setLoadingContractInfo(false);
        }
        networking.current['contractData'] = false;
    }

    const values = {
        initialized,
        /** Wallet */
        getWalletDetails: getUserData,
        userLoading,
        user,

        /** Contract */
        getContractInfo: getContractData,
        loadingContractInfo,
        contractInfo,
        contracts: BlockChain.contracts,

        /** library */
        library: BlockChain.library,
        wallet: { account: address },

        /** Web3 ewwww why no ethers :( */
        ethWeb3,
        web3,
    };
    return <ContractContext.Provider value={values}>{props.children}</ContractContext.Provider>;
};

export default WagmiListener;
