import React from 'react';
// Material UI
import { withStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
// styles
import styles from './styles';

const SkeletonTableCOlumn = ({ classes, ...props }) => {
    return <Skeleton variant="rect" height={20} className={classes.root} />;
};

export default withStyles(styles)(SkeletonTableCOlumn);
