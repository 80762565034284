import React from 'react';
import useStyles from './styles';
import { Typography } from '@mui/material';

const PageTitle = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    Vote on a Proposal
                </Typography>
                <Typography className={classes.body} variant="h6">
                    The DAO works by giving Axion shareholders a chance to vote on prominent changes to the Axion ecosystem,
                    <br className="break-on-large" />
                    with votes being counted equally per wallet, as long as you have at least <b>1M AXN staked for 1825+ days</b>.
                    <br className="break-on-large" />
                    Qualified shareholders will submit their votes by signing a free transaction in their MetaMask wallet on the new Axion
                    DAO.
                </Typography>
            </div>
        </div>
    );
};

export default PageTitle;
