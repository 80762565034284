import axios from 'axios';
import { DAO_GQL_ENDPOINT, DAO_GET_PROPOSAL, NETWORK, DAO_SPACE_NAME, DAO_VOTE_API, DAO_HIDDEN_VOTES } from './variables';
import file1 from './votes/QmNa4cHsxGfrxjKPvv35wBgkt337nKYQi5ZwZNfiG5vx8x.json';
import file2 from './votes/QmNosggdoL1hPPTLkmddJoSfhiy6m3MKt1CEZJWTu54eFT.json';
import file3 from './votes/QmP9jA1KeGT1vuitktSZYKoWBKSv15hJrF5ieGmHVq5wgG.json';
import file4 from './votes/QmRFQ6kEBG7Vdra3rGSh3aPBWhj34t1c56f6gWDsNzXbS9.json';
import file5 from './votes/QmRYJyWtdSLckrN13y5ehRU1UvASZZvmZW9FgUQnPt8FJ6.json';
import file6 from './votes/QmVch6NuQxa5c9EvuBDLpG8dosuExXtm7iNjYZjxmhXNW8.json';
import file7 from './votes/QmVr87Y6uEKX9Lv6jS5Y62j8r4mXVqsoKok4T4ooTV8vhN.json';
import file8 from './votes/QmWZpcg83RSEDpjQARHsreHvzZGcBpyTkRM4JbuXHEX596.json';
const cache = {
    QmNa4cHsxGfrxjKPvv35wBgkt337nKYQi5ZwZNfiG5vx8x: file1,
    QmNosggdoL1hPPTLkmddJoSfhiy6m3MKt1CEZJWTu54eFT: file2,
    QmP9jA1KeGT1vuitktSZYKoWBKSv15hJrF5ieGmHVq5wgG: file3,
    QmRFQ6kEBG7Vdra3rGSh3aPBWhj34t1c56f6gWDsNzXbS9: file4,
    QmRYJyWtdSLckrN13y5ehRU1UvASZZvmZW9FgUQnPt8FJ6: file5,
    QmVch6NuQxa5c9EvuBDLpG8dosuExXtm7iNjYZjxmhXNW8: file6,
    QmVr87Y6uEKX9Lv6jS5Y62j8r4mXVqsoKok4T4ooTV8vhN: file7,
    QmWZpcg83RSEDpjQARHsreHvzZGcBpyTkRM4JbuXHEX596: file8,
};

/**
 *  Gets the non-hidden proposals from the Axion DAO.
 *  @return {Promise<any>} All proposals, where the keys are proposal ID's.
 */
export const getProposals = async () => {
    const proposals = [];
    const response = (
        await axios({
            url: DAO_GQL_ENDPOINT,
            method: 'post',
            data: {
                query: `query Proposals {
                proposals(first: 20, skip: 0, where: { space_in: ["${DAO_SPACE_NAME}"] }, orderBy: "created", orderDirection: desc) {
                    id
                    title
                    body
                    choices
                    start
                    end
                    snapshot
                    state
                    type
                }
            }`,
            },
        })
    ).data;

    // Make sure its not hidden
    response.data.proposals.forEach((p) => {
        if (!DAO_HIDDEN_VOTES.includes(p.id)) proposals.push(p);
    });

    return proposals;
};

/**
 *  Gets the proposal votes from the Axion DAO.
 *  @param {string} id The ID of the proposal
 *  @return {Promise<any>} The voters of the proposal
 */
export const getVoteData = async (id) => {
    const response = (
        await axios({
            url: DAO_GQL_ENDPOINT,
            method: 'post',
            data: {
                query: `
            query getVotes {
                votes (first: 1000, skip: 0, where: { proposal: "${id}" }) {
                    id
                    voter
                    created
                    choice
                }
            }`,
            },
        })
    ).data;

    return response.data.votes;
};

/**
 *  Gets the current votes for a proposal on the Axion DAO.
 *  @param {any} id The ID of the proposal
 *  @param {any} proposal The proposal
 *  @param {any} web3 The web3 instance
 *  @return {Promise<any>} The votes of a proposal.
 */
export const getVotes = async (id, proposal, contracts) => {
    const votes = await getVoteData(id);
    const choices = proposal.choices;

    const now = Date.now() / 1000;
    const isOver = proposal.end <= now;
    let sharesOf = new Array(votes.length).fill(0);

    // Formatting
    const votesByAddress = {};
    sharesOf.forEach((shares, i) => {
        const voter = votes[i].voter;

        votesByAddress[voter] = {
            shares: Math.floor(shares / 1e18) || +isOver,
            vote: Array.isArray(votes[i].choice) ? votes[i].choice.map((c) => choices[c - 1]).join(', ') : choices[votes[i].choice - 1],
        };
    });

    // Tally & sort the current results
    let sortableVoteResults = [];
    Object.keys(votesByAddress).forEach((addr) => {
        const result = votesByAddress[addr];
        if (result.shares) {
            result.vote.split(',').forEach((v) => {
                const choice = v.trim();
                let index = sortableVoteResults.findIndex((s) => s.choice === choice);
                if (index !== -1) sortableVoteResults[index].total += result.shares;
                else sortableVoteResults.push({ total: result.shares, choice });
            });
        }
    });

    const voteResults = {};
    sortableVoteResults.sort((a, b) => b.total - a.total).forEach((r) => (voteResults[r.choice] = r.total));

    const totalVotedShares = Object.keys(voteResults).reduce((acc, cur) => acc + voteResults[cur], 0);

    const results = {
        proposal,
        done: true,
        valid: true,
        proposalId: id,
        totalVotedShares,
        totals: voteResults,
        voters: votesByAddress,
    };

    if (NETWORK === 'ropsten') console.log('Vote Results', results);

    return results;
};

/**
 *  Gets the votes for a proposal on the Axion DAO from Ethereum.
 *  @param {any} id The ID of the proposal
 *  @return {any} The votes of a proposal.
 */
export const getVotesFromCache = (id) => {
    if (cache[id]) return cache[id];
    else return { valid: false, done: false };
};

const generateMessage = (proposal, choice) => {
    return {
        version: '0.1.3',
        timestamp: (Date.now() / 1e3).toFixed(),
        space: DAO_SPACE_NAME,
        type: 'vote',
        payload: {
            proposal,
            choice,
            metadata: {},
        },
    };
};

/**
 *  Vote on a proposal
 *
 *  @param {string} id The ID of the proposal to vote in
 *  @param {number} choice The option to vote on
 *  @param {string} address The address placing the vote
 *  @param {any} web3 The web3 object to make the request with
 *  @returns {Promise<any>} Promise that resolves on successful vote
 */
export const vote = (id, choice, address, web3) => {
    const msg = JSON.stringify(generateMessage(id, choice));
    return new Promise(async (resolve, reject) => {
        web3.currentProvider.sendAsync(
            {
                method: 'personal_sign',
                params: [msg, address],
                from: address,
            },
            async (err, result) => {
                if (err) return reject(err);
                if (result.error) return reject(result.error);

                try {
                    resolve(
                        (
                            await axios.post(DAO_VOTE_API, {
                                msg,
                                address,
                                sig: result.result,
                            })
                        ).data,
                    );
                } catch (e) {
                    console.error(e);
                    reject({ message: `Error placing vote: ${e.response.data.error_description}` });
                }
            },
        );
    });
};

/**
 *  Download the results of a vote to a csv file.
 *
 *  @param {string} id The ID of the proposal.
 */
export const resultsToCSV = async (id) => {
    const vote_data = await axios.get(`${DAO_GET_PROPOSAL}/${id}`);

    const csv_obj = Object.entries(vote_data.data)
        .map((vote) => {
            const date = new Date(vote[1].msg.timestamp * 1000);
            return {
                address: vote[0],
                choice: vote[1].msg.payload.choice,
                timestamp: vote[1].msg.timestamp,
                date_local: date.toLocaleString().replace(',', ''),
                date_utc: date.toUTCString().replace(',', ''),
            };
        })
        .sort((a, b) => a.timestamp - b.timestamp, 0);

    try {
        let headers = Object.keys(csv_obj[0]).join(',');
        let values = csv_obj.map((o) => Object.values(o).join(',')).join('\n');
        let csv = headers + '\n' + values;

        const link = document.createElement('a');
        link.setAttribute('href', `data:text/csv;charset=utf-8,${csv}`);
        link.setAttribute('download', `results-${id}.csv`);
        document.body.appendChild(link);
        link.click();
    } catch (e) {
        console.error(e);
    }
};

/**
 *  Get the votes of a proposal without the scores
 *
 *  @param {string} id The ID of the proposal.
 *  @param {string[]} choices The array of choices
 *  @returns {string[]} array of voters
 */
export const getVotesWithoutShares = async (id, choices) => {
    if (cache[id])
        return Object.keys(cache[id].voters).map((address) => {
            return {
                address,
                vote: cache[id].voters[address].vote,
                shares: cache[id].voters[address].shares,
            };
        });

    const votes = await getVoteData(id);
    const votesVithoutShares = [];
    votes.forEach((vote) => {
        votesVithoutShares.push({
            shares: 0,
            address: vote.voter,
            vote: Array.isArray(vote.choice) ? vote.choice.map((c) => choices[c - 1]).join(', ') : choices[vote.choice - 1],
        });
    });

    return votesVithoutShares;
};
