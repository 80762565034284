import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {},
    page: {
        // maxWidth: 1200,
    },
    item: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    card: {
        height: '100%',
        width: '100%',
    },
    spacing: {
        margin: '5% 0',
    },
}));

export default useStyles;
