import { BiRocket } from 'react-icons/bi';
import { CgToolbox } from 'react-icons/cg';
import { RiToolsFill } from 'react-icons/ri';
import { IoMdWallet } from 'react-icons/io';
import { MdDashboard } from 'react-icons/md';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import { FaLandmark } from 'react-icons/fa';

const menuItems = [
    { name: 'Voting', pathname: '/', Icon: HowToVoteIcon },
    { name: 'Staking', external: true, pathname: 'https://stake.axion.network', Icon: FaLandmark },

    {
        name: 'Launch',
        Icon: BiRocket,
        subItems: [
            { name: 'Launches', pathname: '/launch', Icon: AiOutlineFundProjectionScreen },
            { name: 'Pledge', pathname: '/pledge', Icon: GiPayMoney },
            { name: 'Vesting', pathname: '/vesting', Icon: GiReceiveMoney },
        ],
    },
    {
        name: 'Utilities',
        Icon: CgToolbox,
        subItems: [
            // { name: 'Bridge', pathname: '/bridge', Icon: GiArchBridge },
            { name: 'Tools', pathname: '/tools', Icon: RiToolsFill },
            { name: 'Add AXN Token', Icon: IoMdWallet, isMetamask: true },
        ],
    },
];

export default menuItems;
