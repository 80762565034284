import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: 'rgba(0,0,0,0.3)',

        border: `1px solid ${theme.brand.border}`,
        borderTop: 'none',
    },
    mainContent: {
        paddingTop: theme.spacing(2),
    },
    bold: {
        fontWeight: 500,
    },
    thin: {
        fontWeight: 300,
    },
    badge: {
        marginRight: theme.spacing(1.5),
    },
    green: {
        backgroundColor: `${theme.brand.JavaGreen} !important`,
    },
    red: {
        backgroundColor: `${theme.brand.error} !important`,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    refresh: {
        fontSize: '0.9rem',
        cursor: 'pointer',
    },
    pledgeAmount: {
        marginTop: theme.spacing(2),
    },

    sliderRoot: {
        height: 8,
        color: theme.brand.primary,
        width: '95% !important',

        [theme.breakpoints.down('xs')]: {
            width: '90% !important',
        },
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        // marginLeft: -12,
        '&:focus, &:hover': {
            boxShadow: `0px 0px 0px 8px ${theme.brand.primary}4D`,
        },
        // '&$active': {
        //   boxShadow: `0px 0px 0px 12px ${theme.brand.primary}4D`
        // }
    },
    trackRail: {
        height: 8,
        borderRadius: 4,
    },
    luxy: {
        width: '40%',
        padding: theme.spacing(1, 0),
        [theme.breakpoints.down('xs')]: {
            width: '75%',
            paddingBottom: theme.spacing(1),
        },
    },
    warpPledgeContainer: {
        padding: theme.spacing(3, 0, 1, 0),
    },
    buttonSelected: {
        backgroundColor: theme.brand.border,
        border: `3px solid ${theme.brand.border}`,
        '&:hover': {
            backgroundColor: theme.brand.border,
        },
    },
    kyc: {
        padding: theme.spacing(1),
        border: `1px solid ${theme.brand.border}`,
    },
}));

export default useStyles;
