import React, { useState, useEffect } from 'react';
import providers from './providers';
import useStyles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import SlideUp from 'components/Transitions/SlideUp';
import { CircularProgress, Dialog, DialogContent, Grid, Typography, useMediaQuery } from '@mui/material';

const ConnectWalletModal = ({ open, wallet, connect, onClose }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState('');
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    useEffect(() => {
        if (open) {
            document.body.onkeyup = (e) => {
                if (open && e.key === 'f') {
                    startConnection('frame');
                }
            };
        }

        return () => {
            document.body.onkeyup = () => {};
        };

        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (wallet.status === 'connected') onClose();

        // eslint-disable-next-line
    }, [wallet.status]);

    const startConnection = async (id) => {
        if (selected !== id) {
            setSelected(id);

            try {
                await connect(id);
            } catch (err) {
                console.error(err);
            } finally {
                setSelected('');
            }
        }
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            disableScrollLock
            disableEnforceFocus
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <div className={classes.dialogTitle} style={{ justifyContent: 'space-between' }}>
                <Typography variant="h2">Choose Your Wallet</Typography>
                <CloseIcon className={classes.closeIcon} onClick={onClose} />
            </div>

            <DialogContent className={classes.contentContainer}>
                <Grid container spacing={2} justify="center" alignItems="center">
                    {providers
                        .filter((provider) => (isSmall ? provider.id !== 'frame' : provider.id.length > 0))
                        .map((p, idx) => (
                            <Grid item xs={6} onClick={() => startConnection(p.id)} key={idx}>
                                <div className={classes.walletConnectButton}>
                                    {selected === p.id ? (
                                        <CircularProgress size={40} className={classes.loadingIndicator} />
                                    ) : (
                                        <>
                                            {/* eslint-disable-next-line */}
                                            <img src={p.logo} className={classes.walletLogo} />
                                            <br />
                                            <Typography variant="h3">{p.name}</Typography>
                                            <Typography className={classes.subTitle}>{p.subtitle}</Typography>
                                        </>
                                    )}
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ConnectWalletModal;
