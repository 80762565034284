import React, { useContext, useState } from 'react';

// Modules
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { SOCIAL_LINKS } from 'utils/variables';

// Components
import Profile from './Profile';
import Logo from 'components/Logo';
import MenuItems from './MenuItems';
import GasTracker from './GasTracker';
import LiveTicker from './LiveTicker';
import ThemeSwitcher from 'components/ThemeSwitcher';
import TransactionModal from 'components/Modals/TransactionsModal';
import { Drawer, SwipeableDrawer, Hidden, Tooltip } from '@mui/material';
import { ContractContext } from 'web3/WagmiListener';

function NavDrawer({ open, onOpen, onClose }) {
    const classes = useStyles();

    const [showTx, setShowTx] = useState(false);
    const { theme } = useSelector((store) => store);
    const { wallet, user } = useContext(ContractContext);

    const renderContent = () => {
        return (
            <React.Fragment>
                <div className={classes.top}>
                    <div className={classes.wallet}>
                        <Logo />
                        <Profile setShowTx={setShowTx} />
                        <GasTracker />
                    </div>
                    <div className={classes.ticker}>
                        <LiveTicker />
                    </div>
                    <MenuItems />
                </div>

                <div style={{ width: '100%' }}>
                    <div className={classes.bottom}>
                        {user?.hasNFT && user?.hasNFT('SUPERNOVA') && (
                            <div className={classes.themeSwitcher}>
                                <ThemeSwitcher />
                            </div>
                        )}
                        <div className={classes.bottomRow}>
                            {SOCIAL_LINKS.map(({ title, link, Icon }, idx) => (
                                <Tooltip arrow title={title} placement="top" key={`${title}-idx`}>
                                    <a href={link} target="_blank" rel="noreferrer">
                                        <Icon fill={theme.brand.NormalText} className={classes.social} />
                                    </a>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Hidden mdDown>
                <Drawer variant="permanent" classes={{ paper: classes.drawer }}>
                    {renderContent()}
                </Drawer>
            </Hidden>

            <Hidden lgUp>
                <SwipeableDrawer open={open} anchor={'left'} onOpen={onOpen} onClose={onClose} classes={{ paper: classes.drawer }}>
                    {renderContent()}
                </SwipeableDrawer>
            </Hidden>

            <TransactionModal open={showTx} wallet={wallet} onClose={() => setShowTx(false)} />
        </React.Fragment>
    );
}

export default NavDrawer;
