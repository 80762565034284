import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Use
import { useTheme } from '@mui/material';
import useStyles from './styles';
import { useNavigate } from 'react-router';

export default function MediaCard({ href, internal, title, description, lightImage, darkImage, ...props }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles(props);

    const handleClick = () => {
        if (internal) navigate(href);
        else window.open(href);
    };

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={handleClick} className={classes.actionArea}>
                <CardMedia className={classes.media} image={theme.mode === 'dark' ? darkImage : lightImage} />
                <CardContent classes={{ root: classes.content }}>
                    <Typography gutterBottom variant="h5" color="textPrimary">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button onClick={handleClick} size="small" color="primary">
                    Visit
                </Button>
            </CardActions>
        </Card>
    );
}
