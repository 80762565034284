import axios from 'axios';
import Contract from './contract';
import { store } from 'redux/config';
import { base, routes } from 'api/requests';
import { addTx, completedTx } from 'redux/actions/transactions';
import { DEFAULT_GAS_PRICE, GALAXY_INFO_URL, IPFS_API, BACKUP_IPFS_API, POLYGON_RPC, CONTRACT_INFO } from 'utils/variables';
import cloneDeep from 'lodash/cloneDeep';
import Web3 from 'web3';
import ABI from '../abi.json';
import { ethers } from 'ethers';

const dispatch = store.dispatch;

class NFT extends Contract {
    async getNFTs(stakeIDs) {
        const [stakeNFTs, utilityNFTs, colliderNFTs, colliderResults] = await Promise.all([
            this.getStakeNFTs(stakeIDs),
            this.getAxionUtilityNFTs(),
            this.getAxionColliderNFTs(),
            axios(`${base}${routes.getEvents}/Redeemed/${this.account}`).then((response) => {
                return response.data.Items.map((ev) => {
                    return {
                        ...ev,
                        particles: ev.particles / 1e18,
                    };
                });
            }),
        ]);

        return {
            colliderResults,
            collection: [...utilityNFTs, ...colliderNFTs, ...stakeNFTs],
        };
    }

    async getTotalSupplyOf(utilID) {
        let supply = await this.contracts.NFTCollection.totalSupply(utilID);
        return supply;
    }

    /**
     * Purchase NFT using MATIC
     *
     * @param {string} utilID - The ID of the NFT to purchase
     * @param {number} amount - The amount of NFTs to purchase
     */
    async purchaseNFT(utilID, amount) {
        console.log(this.contracts.NFTCollection);

        let tx = await this.contracts.NFTCollection.connect(this.signer).purchaseNative(utilID, amount);

        dispatch(addTx({ id: tx.hash, description: `Purchase NFT using USDC` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    async approveUSDC() {
        let contract = await this.getContract(CONTRACT_INFO.Tokens.ABI, CONTRACT_INFO.Tokens.USDC);
        let tx = await contract.connect(this.signer).approve(this.contracts.NFTCollection.address, ethers.constants.MaxUint256);

        dispatch(addTx({ id: tx.hash, description: `Approving USDC for Utility NFT Purchase` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Redeem Axion Collider NFT
     *
     * @param {string} tokenId - The ID of the NFT
     * @param {number} stakingDays - The number of days to stake if successful
     *
     */
    async redeem(tokenId, stakingDays) {
        let tx = await this.contracts.AxionColliderNFT.connect(this.signer).redeem(tokenId, stakingDays, {
            gasLimit: 1000000,
            gasPrice: DEFAULT_GAS_PRICE,
        });

        // dispatch(addTx({ id: tx.hash, description: `Axion Collider Redemption` }));
        // await tx.wait();
        // dispatch(completedTx(tx.hash));
        return tx;
    }

    /**
     * Get all the Axion Galaxy NFT's of the current user
     */
    async getStakeNFTs(stakeIDs) {
        try {
            if (stakeIDs.length > 0) {
                let nfts = [];
                const uris = [];
                const clonedIds = cloneDeep(stakeIDs);
                while (clonedIds.length) {
                    const ids = clonedIds.splice(0, 200);
                    const stakes = await this.contracts.StakeReader.getTokenUris(ids);
                    uris.push(...stakes);
                }
                // const uris = await Promise.all(stakeIDs.map((id) => this.contracts.StakeToken.tokenURI(id)));
                uris.forEach(async (nft, idx) => {
                    const id = stakeIDs[idx];
                    const data = this.decodeURI(nft.replace('data:application/json;base64,', ''));
                    const image = IPFS_API + data.image.replace('ipfs://', '');
                    const animation_url = IPFS_API + data.animation_url.replace('ipfs://', '');

                    let properties = [];
                    data.attributes.forEach((a) => {
                        let value = a.value;
                        let trait_type = a.trait_type.charAt(0).toUpperCase() + a.trait_type.slice(1);

                        if (trait_type === 'Class') {
                            trait_type = 'Type';
                            value = 'Galaxy';
                        }

                        const excludedTraits = ['Rarity', 'Start Date', 'End Date'];
                        if (!excludedTraits.includes(trait_type)) properties.push({ value, trait_type });
                    });

                    // Cut off a majority of the description to prevent it from overflowing the card
                    let description = data.description.split('This NFT can be')[0];

                    nfts.push({
                        balance: 1,
                        type: 'galaxy',
                        metadata: {
                            id,
                            image,
                            properties,
                            description,
                            animation_url,
                            external_url: GALAXY_INFO_URL,
                            name: data.name || 'Galaxy Stake ' + '#' + id,
                        },
                    });
                });

                return nfts;
            } else return [];
        } catch (err) {
            console.error('getStakeNFTs failed', err);
            return [];
        }
    }

    /**
     * Get all the Axion Collider NFT's of the current user
     */
    async getAxionColliderNFTs() {
        let end = 0;
        let start = 0;

        try {
            end = await this.contracts.AxionColliderNFT.balanceOf(this.account);

            if (end > 0) {
                let nfts = [];

                // TODO: Pagination
                const userNFTs = await this.contracts.AxionColliderNFT.getAllOwnerNfts(this.account, start, end);
                userNFTs.forEach((nft) => {
                    const data = this.decodeURI(nft.uri.replace('data:application/json;base64,', ''));
                    const image_url = IPFS_API + data.image.replace('ipfs://', '');
                    const particles = nft.data.particles / 1e18;

                    let properties = [];
                    data.attributes.forEach((a) => {
                        let value = a.value;
                        let trait_type = a.trait_type.charAt(0).toUpperCase() + a.trait_type.slice(1);

                        if (a.trait_type === 'rarity') {
                            properties.push({ value: this.getRarityString(a.value), trait_type: 'Rarity' });

                            trait_type = 'Multiplier';
                            value = `${a.value}x`;
                        }

                        properties.push({ value, trait_type });
                    });

                    nfts.push({
                        balance: 1,
                        type: 'particle',
                        metadata: {
                            particles,
                            id: nft.id,
                            properties,
                            image: image_url,
                            animation_url: image_url,
                            description: data.description,
                            external_url: 'https://support.axion.network',
                            name: `${Math.round(particles).numberWithCommas(0)} Particles`,
                        },
                    });
                });

                return nfts;
            } else return [];
        } catch (err) {
            console.error('getAxionColliderNFTs failed', err);
            return [];
        }
    }

    /**
     * Get all the Axion Collider NFT's of the current user
     */
    async getAxionUtilityNFTs() {
        let utils = await this.getUtilities();
        let balances = new Array(utils.length).fill(1);

        try {
            // if (window.location.hostname !== 'localhost')
            balances = await this.getBalanceBatch(utils.map((n) => n.id));
        } catch (err) {
            console.error('Failed to get Axion Utility NFT balances', { utils, balances });
        }

        const metadatas = await Promise.all(utils.map((util) => this.getIPFS(util.ipfsMetadataHash)));
        if (!metadatas || metadatas.length === 0) {
            console.error('Failed to get utility FNT metadata. Please refresh and try again.');
            return [];
        }

        let nfts = [];
        utils.forEach(async (n, i) => {
            // Fill these in with defaults, which are not in contract right now
            if (metadatas?.[i]?.name === 'LP Replenishment' && metadatas?.[i]?.properties?.length === 0) {
                metadatas[i].properties = [
                    { trait_type: 'Type', value: 'Utility' },
                    { trait_type: 'Rarity', value: 'Ultra-Rare' },
                    { trait_type: 'Status', value: 'Transferrable, Sellable' },
                    { trait_type: 'Function', value: 'OG Benefits' },
                ];
            }

            try {
                let image_hash = '';
                let animation_hash = '';
                let meta = metadatas[i];
                if (meta) {
                    image_hash = meta.image;
                    animation_hash = meta.animation_url || meta.image;
                    const metadata = {
                        id: n.id,
                        ...meta,
                        ipfs: n.ipfsMetadataHash,
                        image: IPFS_API + image_hash.replace('ipfs://', ''),
                        animation_url: IPFS_API + animation_hash.replace('ipfs://', ''),
                        purchaseInfo: {
                            mintable: n.mintable,
                            maxSupply: n.maxSupply,
                            mintPrice: n.mintPrice,
                            maxPurchaseTx: n.maxPurchaseTx,
                            mintPriceNative: n.mintPriceNative,
                            purchaseWithAxion: n.purchaseWithAxion,
                        },
                    };

                    nfts.push({ type: 'utility', metadata, balance: +balances[i] });
                }
            } catch (err) {
                console.error('getAxionUtilityNFTs: Failed to get NFT metadata', {
                    message: err.message,
                    uri: n.ipfsMetadataHash,
                });
            }
        });

        return nfts.sort((a, b) => +b.metadata.id - +a.metadata.id);
    }

    /**
     * Get the balance of an NFT for the current user
     * based on IDs provided
     *
     * @param {string} ids - The IDs of the NFTs
     */
    getBalanceBatch(ids) {
        const addresses = new Array(ids.length).fill(this.account);
        return this.contracts.NFTCollection.balanceOfBatch(addresses, ids);
    }

    /**
     * Get the URI of an ERC1155 token
     *
     * @param {string} id - ID of the token to check
     */
    getURI(id) {
        return this.contracts.NFTCollection.uri(id);
    }

    /**
     * Get all the utilities
     */
    getUtilities() {
        return this.contracts.NFTCollection.getUtilities();
    }

    /**
     * Decode the Axion Collider URI
     *
     * @param {string} uri - Base64 encoded URI
     */
    decodeURI(uri) {
        return JSON.parse(Buffer.from(uri, 'base64').toString());
    }

    /**
     * Gets the rarity value for a given rarity number
     * for collider nfts
     *
     * @param {string|number} rarity - The rarity number
     */
    getRarityString(rarity) {
        rarity = parseInt(rarity);
        switch (rarity) {
            case 1:
                return 'Common';
            case 2:
                return 'Uncommon';
            case 3:
                return 'Rare';
            case 4:
                return 'Semi-rare';
            case 5:
                return 'Ultra-rare';
            case 10:
                return 'Legendary';
            default:
                return 'Common';
        }
    }

    /**
     * Get data from IPFS hash
     *
     * @param {string} hash - IPFS hash
     */
    async getIPFS(hash) {
        try {
            const request = await axios.get(`${IPFS_API}${hash}`);
            if (request.status === 200) return request.data;
            else if (request.status === 429) {
                const req = await axios.get(`${BACKUP_IPFS_API}${hash}`);
                if (req.status === 200) return req.data;
            } else return null;
        } catch (err) {
            console.error('Failed to get IPFS data', { hash, err });
            return null;
        }
    }
}

export default NFT;
