import React from 'react';
// Components
import { Typography } from '@mui/material';
// styles
import useStyles from './styles';

const PageTitle = ({ type }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.barTitle}>
                <Typography className={classes.title} variant="h1">
                    {type === 'official' ? 'Official ' : 'Community '}Tools
                </Typography>
                {type === 'official' && (
                    <Typography className={classes.body} variant="h6">
                        Some helpful tools provided directly by the Axion Foundation to assist you along your Axion journey!
                    </Typography>
                )}
                {type === 'community' && (
                    <Typography className={classes.body} variant="h6">
                        Our incredible community has also created several tools to assist you along your Axion journey, check them out
                        below!
                        <br /> <br />
                        <span className="bold">Disclaimer:</span> These tools were made by members of our community to help you with your
                        Axion investment journey.
                        <br />
                        Please note that Axion Foundation does not maintain control of these websites and cannot guarantee their content.
                    </Typography>
                )}
            </div>
            <div className={classes.barStats}></div>
        </div>
    );
};

export default PageTitle;
