import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, TablePagination, TableSortLabel, Collapse, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from './styles';

const CustomizedTable = ({
    classes,
    onSort,
    pagination = true,
    count = 0,
    headers = [],
    rowsPerPage = 25,
    page,
    rows,
    onChangePage,
    onChangeRows,
    emptyMessage,
    tooltips = {},
    initialOrderIndex = 2,
    perPageOptions = [5, 10, 25, 50],
}) => {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState(initialOrderIndex); // 2 is the index for the column 'date'

    function handleRequestSort(e, index) {
        let newOrder = order;
        if (orderBy === index) {
            if (order === 'desc') {
                newOrder = 'asc';
                setOrder(newOrder);
            } else {
                newOrder = 'desc';
                setOrder(newOrder);
            }
        }
        setOrderBy(index);

        if (typeof onSort === 'function') {
            onSort(newOrder, index);
            return;
        }
    }

    function handlePageChange(e, page) {
        if (typeof onChangePage !== 'undefined') {
            onChangePage(e, page);
        }
    }

    function handleChangeRowsPerPage(e) {
        if (typeof onChangeRows !== 'undefined') {
            onChangeRows(e);
        }
    }

    return (
        <div className={classes.root}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell
                                    classes={{
                                        body: classes.body,
                                        head: classes.head,
                                    }}
                                    key={`header-${index}`}
                                >
                                    {header && (
                                        <TableSortLabel
                                            active={orderBy === index}
                                            direction={order}
                                            onClick={(e) => handleRequestSort(e, index)}
                                            classes={{
                                                root: classes.tableSortLabel,
                                                active: classes.tableSortLabelActive,
                                                icon: classes.tableSortLabelActive,
                                            }}
                                        >
                                            {header}
                                            {tooltips[header] && (
                                                <Tooltip enterDelay={0} title={tooltips[header] || 'Sort'}>
                                                    <InfoOutlined className={classes.info} />
                                                </Tooltip>
                                            )}
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rindex) => {
                            return (
                                <React.Fragment key={`row-${rindex}`}>
                                    <TableRow key={`row-${rindex}`} style={{ borderBottomWidth: row.expanded ? 0 : '1px' }}>
                                        {row.columns.map((column, cindex) => {
                                            return (
                                                <TableCell
                                                    style={{ cursor: row.handleOpenRow ? 'pointer' : 'auto' }}
                                                    onClick={() => (row.handleOpenRow ? row.handleOpenRow() : null)}
                                                    key={`${rindex}-col-${cindex}`}
                                                    classes={{
                                                        body: classes.body,
                                                        head: classes.head,
                                                    }}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {column}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                    {row.expandedItemComponent && (
                                        <TableRow key={`row-${rindex}-expanded`}>
                                            <TableCell className={classes.expandContainer} colSpan={12}>
                                                <Collapse in={row.expanded}>{row.expandedItemComponent}</Collapse>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {pagination && (
                <table className={classes.pagination}>
                    <tbody>
                        <tr>
                            <TablePagination
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={perPageOptions}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                classes={{
                                    root: classes.paginationRoot,
                                }}
                            />
                        </tr>
                    </tbody>
                </table>
            )}

            {rows.length === 0 && emptyMessage && <Typography className={classes.noData}>{emptyMessage}</Typography>}
        </div>
    );
};

export default withStyles(styles)(CustomizedTable);
