import Contract from './contract';
import abi from '../abi.json';
import { store } from 'redux/config';
import { MaxUint256 } from '@ethersproject/constants';
import { CONTRACT_INFO } from 'utils/variables';
import { addTx, completedTx } from 'redux/actions/transactions';
import Web3 from 'web3';

const ERC20 = abi.ERC20;

const dispatch = store.dispatch;

class Pledge extends Contract {
    constructor(params) {
        super();
        this.signer = params?.signer;
        this.contracts = params?.contracts;
        this.address = params?.address;
        this.account = params?.address;
        this.provider = params?.provider;

        this.methods = {
            YearStake: '0',
            OG5555: '1',
            OGPoly: '2',
            MaxStake: '3',
        };
    }

    async getPledges() {
        let nameLength = 0;

        let tiers = [];
        let pledges = [];
        let pledgeItems = [];
        let pledgeNames = [];

        try {
            [pledgeItems, nameLength] = await Promise.all([
                this.contracts.PledgeEngine.getAllItems(),
                this.contracts.PledgeEngine.getNamesLength(),
            ]);

            if (nameLength > 0) {
                try {
                    pledgeNames = await this.contracts.PledgeEngine.getNames(0, nameLength);
                } catch (e) {
                    return { pledges };
                }

                pledgeItems.forEach((item, i) => {
                    const totalPledged = item.pledged / 1e6;
                    let tokenPerDollar = item.tokenPerDollar / 1e18;
                    const totalPledgeAmount = item.maxPledged / 1e6;

                    const now = new Date();
                    const end = new Date(item.end * 1000);
                    const start = new Date(item.start * 1000);
                    const isActive = now >= start && now <= end && totalPledged < totalPledgeAmount;

                    if (tokenPerDollar < 1) tokenPerDollar *= 1e6;

                    tiers = item.tiers;
                    pledges.push({
                        isActive,
                        totalPledged,
                        tokenPerDollar,
                        totalPledgeAmount,
                        name: pledgeNames[i],
                        end: parseInt(item.end),
                        start: parseInt(item.start),
                        pricePerToken: 1 / tokenPerDollar,
                        kycRequired: item.kycRequired || false,
                        pledgeToken: { symbol: 'USDC', network: 'Polygon', address: CONTRACT_INFO.Tokens.USDC, networkID: 137 },
                        tiers: item.tiers.map((tier) => {
                            return {
                                method: tier.method,
                                required: tier.required,
                                allocation: tier.allocation / 1e6,
                            };
                        }),
                    });
                });
            }
        } catch (err) {
            console.error('Cannot get all pledge items', err);
        }

        pledges = [...pledges, ...this.getStaticPledges()];
        return { pledges };
    }

    async getUserAllocationPerPledge(user) {
        let pledges = [];
        let allocations = [];
        if (pledges.length === 0) {
            try {
                const result = await this.getPledges();
                pledges = result.pledges;
            } catch (e) {
                console.log('Unable to get pledges', e);
            }
        }

        pledges.forEach((pledge) => {
            const allocation = this.calculateMaxAllocation(pledge.tiers, user);
            allocations.push({ pledge, allocation });
        });

        return allocations;
    }

    async getUserUSDCInfo() {
        try {
            const [balance, allowance] = await Promise.all([
                this.getBalanceOf(CONTRACT_INFO.Tokens.USDC),
                this.getAllowanceOf(CONTRACT_INFO.Tokens.USDC, CONTRACT_INFO.PledgeEngine.ADDRESS),
            ]);

            return {
                balance: balance / 1e6,
                allowance: allowance / 1e18,
            };
        } catch (err) {
            console.error('Unable to get user usdc info', err);
            return { balance: 0, allowance: 0 };
        }
    }

    async getGlobalPledges() {
        return this.contracts.PledgeEngine.getAllItems();
    }

    async getUserPledges() {
        let length = 0;

        try {
            length = await this.contracts.PledgeEngine.getUserPledgesLength(this.account);
        } catch (e) {
            console.log('Unable to get UserPledgesLength', e);
        }

        // Get the user's pledges, if any
        let pledges = [];
        if (length > 0) {
            try {
                pledges = await contracts.PledgeEngine.getUserItems(this.account, 0, length);
                return pledges
                    .map((pledge) => {
                        const end = parseInt(pledge.item.end);
                        const start = parseInt(pledge.item.start);
                        const maxPledged = pledge.item.maxPledged / 1e6;
                        return {
                            id: start + end + maxPledged,
                            record: pledge.record,
                            item: pledge.item,
                        };
                    })
                    .map((p) => {
                        let tokens = p.record.tokens / 1e18;
                        if (tokens < 1) tokens *= 1e6;

                        return {
                            tokens,
                            id: p.id,
                            amount: p.record.amount / 1e6,
                        };
                    });
            } catch (e) {
                console.log('Unable to get UserPledges', e);
            }
        }

        return pledges;
    }

    /**
     * Get the users max allocation for a pledge
     *
     * @param {any[]} tiers - The name of the pledge item
     * @param {any} user - The user object
     */
    calculateMaxAllocation(tiers, user) {
        if (tiers.length === 0 || !user) return { id: 0, allocation: 0 };

        let allocation = 0;
        let highestTierID = 0;
        let approvedMethods = [];
        let stakes = user.stakes;
        const stakes350 = stakes.filter((stake) => stake.stakeDays >= 350 && stake.principal >= 4500000 && !stake.isMatured);
        const stakes5555 = stakes.filter((stake) => stake.stakeDays >= 5555 && stake.principal >= 4500000 && !stake.isMatured);

        for (let i = 0; i < tiers.length; i++) {
            const tier = tiers[i];
            switch (tier.method) {
                case this.methods.YearStake:
                    if (stakes350.length > 0) {
                        allocation += tier.allocation;
                        highestTierID = stakes350[0].id;
                        approvedMethods.push(this.methods.YearStake);
                    } else if (tier.required) return { id: 0, allocation: 0 };
                    break;

                case this.methods.OG5555:
                    if (user.hasNFT('OG5555-2.5')) {
                        allocation += tier.allocation;
                        approvedMethods.push(this.methods.OG5555);
                    } else if (tier.required) return { id: 0, allocation: 0 };
                    break;

                case this.methods.OGPoly:
                    if (user.hasNFT('OGPOLYGON')) {
                        allocation += tier.allocation;
                        approvedMethods.push(this.methods.OGPoly);
                    } else if (tier.required) return { id: 0, allocation: 0 };
                    break;

                case this.methods.MaxStake:
                    if (stakes5555.length > 0) {
                        allocation += tier.allocation;
                        highestTierID = stakes5555[0].id;
                        approvedMethods.push(this.methods.MaxStake);
                    } else if (tier.required) return { id: 0, allocation: 0 };
                    break;

                default:
                    return { id: 0, allocation: 0 };
            }
        }

        return { id: highestTierID, allocation, approvedMethods };
    }

    /**
     * Pledge an amount towards a project.
     *
     * @param {string} name - The name of the pledge item
     * @param {string} amount - The amount of usdc to pledge
     * @param {string} id - The id of the stake that qualifies the pledge
     */
    async sendPledge(name, amount, stakeID) {
        amount = Web3.utils.toWei(`${amount}`, 'mwei'); // 1e6
        let tx = await this.contracts.PledgeEngine.connect(this.signer).axionLaunchPledge(name, amount, stakeID);
        dispatch(addTx({ id: tx.hash, description: `Pledge $${amount / 1e6} USDC to ${name}` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Approve USDC for use in the pledge engine
     */
    async approveUSDC() {
        const contract = this.getContract(ERC20.ABI, CONTRACT_INFO.Tokens.USDC);
        let tx = await contract.connect(this.signer).approve(CONTRACT_INFO.PledgeEngine.ADDRESS, MaxUint256, { gasPrice });

        dispatch(addTx({ id: tx.hash, description: 'Pledge Engine Approval (USDC)' }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    // Pledges that happened in the past
    getStaticPledges() {
        return [
            {
                isActive: false,
                totalPledged: 250000,
                tokenPerDollar: 104,
                totalPledgeAmount: 250000,
                name: 'Vabble',
                end: 1627610400,
                start: 1625191200,
                pricePerToken: 1 / 104,
                kycRequired: false,
                tiers: [],
                pledgeToken: { symbol: 'USDC', network: 'Ethereum', address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', networkID: 1 },
            },
            {
                isActive: false,
                totalPledged: 500000,
                tokenPerDollar: 44.4,
                totalPledgeAmount: 500000,
                name: 'Sekuritance',
                end: 1621130400,
                start: 1619920800,
                pricePerToken: 1 / 44.4,
                kycRequired: false,
                tiers: [],
                pledgeToken: { symbol: 'USDC', network: 'Ethereum', address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', networkID: 1 },
            },
        ];
    }
}

export default Pledge;
