import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
        width: '100%',
        position: 'relative',
    },
}));

export default useStyles;
