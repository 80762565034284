import { makeStyles } from '@mui/styles';
import { drawerWidth } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    logo: {
        height: 160,
        width: 'auto',
    },
    content: {
        flexGrow: 1,
        marginLeft: drawerWidth,
        maxWidth: '100%',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            padding: 0,
            paddingBottom: theme.spacing(2),
            // marginTop: NavigationBarHeight,
        },
    },
    contentMoveDown: {
        paddingTop: theme.spacing(8),
    },

    bottomNav: {
        width: '100%',
        maxWidth: '100%',
        position: 'fixed',
        bottom: 0,
        boxShadow: theme._shadows.bottom,
        zIndex: 99,
        backgroundColor: theme.brand.NavigationBackground,
    },
    icon: {
        fontSize: 22,
    },
    error: {
        borderRadius: 0,
    },
    connect: {
        zIndex: 10,
        position: 'fixed',
        width: '100%',
        backgroundColor: theme.brand.accent,
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

export default useStyles;
