import cloneDeep from 'lodash/cloneDeep';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';

const reducer = (state = [], { type, payload }) => {
    switch (type) {
        case ADD_TRANSACTION:
            return addTx(cloneDeep(state), payload);
        case UPDATE_TRANSACTION:
            return updateTx(cloneDeep(state), payload);
        case DELETE_TRANSACTION:
            return state.filter((tx) => tx.id !== payload);
        case CLEAR_TRANSACTIONS:
            return state.filter((tx) => tx.address !== payload);
        default:
            return state;
    }
};

const addTx = (stateCopy, tx) => {
    if (stateCopy.length >= 10) return [...stateCopy.slice(-5), tx];
    else return [...stateCopy, tx];
};

const updateTx = (stateCopy, payload) => {
    const idx = stateCopy.findIndex((s) => s.id === payload.id);
    if (idx === -1) return stateCopy;

    stateCopy[idx].pending = false;
    stateCopy[idx].failed = payload.failed;
    stateCopy[idx].timeCompleted = Date.now();

    return stateCopy;
};

export default reducer;
