import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: theme.spacing(4),
        marginBottom: theme.spacing(5),

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'justify',
        },
    },

    title: {
        fontSize: 42,
        fontWeight: '500',
    },
    body: {
        fontSize: 15,
        textTransform: 'none',
        marginTop: 10,
        fontWeight: 300,
        color: theme.brand.SecondaryText,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    container: {
        width: '100%',
    },
}));

export default useStyles;
