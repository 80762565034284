import React from 'react';
import useStyles from './styles';

import { useDispatch } from 'react-redux';
import copyPromise from 'utils/copy-promise';
import CloseIcon from '@mui/icons-material/Close';
import useTransactions from 'hooks/useTransactions';
import SlideUp from 'components/Transitions/SlideUp';
import { setSnackbar } from 'redux/actions/snackbar';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CallMadeIcon from '@mui/icons-material/CallMade';
import providers from '../ConnectWalletModal/providers';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { openTx, openAddress } from 'utils/open-etherscan';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Grid, Button, Dialog, Typography, CircularProgress, useMediaQuery, Box, IconButton } from '@mui/material';

const TransactionsModal = ({ open, wallet, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { transactions, clearTransactions } = useTransactions(true);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const copyAddress = async () => {
        try {
            await copyPromise(wallet.account);
            dispatch(setSnackbar({ message: 'Address copied', type: 'success' }));
        } catch (err) {
            dispatch(setSnackbar('Failed to copy address'));
        }
    };

    return (
        <Dialog
            fullWidth
            open={open}
            maxWidth="xs"
            onClose={onClose}
            disableScrollLock
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper }}
        >
            <Box className={classes.dialogTitle}>
                <Box>
                    <Typography variant="h3">Account</Typography>
                </Box>
                <IconButton sx={{ marginLeft: 1 }} onClick={() => onClose()}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>

            <div className={classes.addressBox}>
                <Typography variant="h4" className={classes.wallet}>
                    {providers.find((p) => p.id === wallet?.connector)?.name ?? 'Your'} Wallet
                    <br />
                    <span className={classes.walletAddress} onClick={() => copyAddress()}>
                        {wallet.account?.shortenAddress()}
                    </span>
                </Typography>
                <Grid container justify="space-evenly">
                    <Grid item xs={6}>
                        <Button onClick={() => copyAddress()}>
                            <FileCopyIcon className={classes.buttonIcon} />
                            Copy{isSmall ? '' : ' Full Address'}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => openAddress(wallet.account)}>
                            <OpenInNewIcon className={classes.buttonIcon} />
                            {isSmall ? '' : 'View on '}Polygonscan
                        </Button>
                    </Grid>
                </Grid>
            </div>

            <Box className={classes.dialogTitle}>
                <Typography variant="h3">Recent Transactions</Typography>
                {transactions.length > 0 && (
                    <Box sx={{ marginTop: 1, marginLeft: 2 }} onClick={() => clearTransactions()} className={classes.clear}>
                        <Typography variant="h6" className={classes.undoTransform}>
                            (clear)
                        </Typography>
                    </Box>
                )}
            </Box>

            <div className={classes.contentContainer}>
                {transactions.length === 0 && (
                    <div className={classes.emptyTransactions}>
                        <Typography variant="h5" align="center" className={classes.undoTransform}>
                            No recent transactions...
                        </Typography>
                    </div>
                )}

                <div className={classes.transactions}>
                    {transactions.slice(0, 4).map((t, idx) => (
                        <Grid container alignItems="center" justify="space-between" spacing={1} key={idx}>
                            <Grid item xs={11}>
                                <Typography variant="h5" display="inline" onClick={() => openTx(t.id)} className={classes.transaction}>
                                    {t.description} <CallMadeIcon className={classes.openArrow} />
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <center>
                                    {t.pending ? (
                                        <CircularProgress size="1rem" />
                                    ) : !t.failed ? (
                                        <CheckCircleOutlineIcon className={classes.success} />
                                    ) : (
                                        <ErrorOutlineIcon className={classes.failed} />
                                    )}
                                </center>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </div>
        </Dialog>
    );
};

export default TransactionsModal;
