import React, { useState, useEffect } from 'react';
// Modules
import { useLocation } from 'react-router-dom';
// Components
import { Alert as MuiAlert, Slide, Hidden, Box, Button, Typography } from '@mui/material';
import NavDrawer from './NavDrawer';
import NavigationBar from './NavigationBar';
// import errorTranslate from 'utils/error-translators';
import clsx from 'classnames';
import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
// Styles
import useStyles from './styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Navigation({ children, ...props }) {
    const classes = useStyles();
    const location = useLocation();
    const { theme } = useSelector((store) => store);
    const { open } = useWeb3Modal();

    const { isConnected } = useAccount();
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    useEffect(() => {
        setMobileDrawerOpen(false);
    }, [location]);

    return (
        <div className={classes.root}>
            <Hidden lgUp>
                <NavigationBar onMenuClicked={() => setMobileDrawerOpen(true)} />
            </Hidden>
            <NavDrawer open={mobileDrawerOpen} onOpen={() => setMobileDrawerOpen(true)} onClose={() => setMobileDrawerOpen(false)} />
            <main className={clsx(classes.content)}>
                <Slide direction="down" in={!isConnected} mountOnEnter unmountOnExit>
                    <div className={classes.connect}>
                        <Button
                            color="primary"
                            variant="contained"
                            override={theme.mode === 'supernova' ? 'supernova' : 'blue'}
                            onClick={() => open()}
                        >
                            Connect Your Wallet
                        </Button>
                    </div>
                </Slide>
                <Box className={clsx({ [classes.contentMoveDown]: !isConnected })}>{children}</Box>
            </main>
        </div>
    );
}

export default Navigation;
