import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
        maxWidth: '80vw',
        padding: theme.spacing(4),
        [theme.breakpoints.only('lg')]: {
            maxWidth: '80vw',
            padding: `24px 8px`,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            padding: `16px 8px`,
        },
    },
}));

export default useStyles;
