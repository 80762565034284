import { cloneDeep } from 'lodash';
import theme from '../../theme';

export const SET_THEME = 'SET_THEME';
export const THEME_ERROR = 'THEME_ERROR';
export const INIT = cloneDeep(theme);

function reducer(state = INIT, { type, payload }) {
    switch (type) {
        case SET_THEME:
            return payload;
        case THEME_ERROR:
        default:
            return state;
    }
}

export default reducer;
