import React, { useContext, useEffect, useState } from 'react';
import useStyles from './styles';
import menuItems from './menu_items';
import NavigationItem from '../NavItem';
import { List } from '@mui/material';
import { useSelector } from 'react-redux';
import { getProposals } from 'utils/voting';
import { ContractContext } from 'web3/WagmiListener';
import { ETH_CHAIN, BSC_CHAIN, STAKE_TYPES } from 'utils/variables';

const HIDDEN_CHAIN_MENU_ITEMS = [ETH_CHAIN, BSC_CHAIN];
const MenuItems = () => {
    const classes = useStyles();
    const { user } = useContext(ContractContext);
    const { launches, chain } = useSelector((store) => store);

    const [activeVotes, setActiveVotes] = useState(0);
    const [matureStakes, setMatureStakes] = useState(0);
    const [activeLaunches, setActiveLaunches] = useState(0);

    useEffect(() => {
        if (launches.launches) setActiveLaunches(launches.launches.filter((l) => l['al-state'] === 'current').length);
    }, [launches]);

    useEffect(() => {
        if (user) {
            const mature = user.sortedSessions?.[STAKE_TYPES.MATURED_STAKES];
            if (mature && mature.length > 0) setMatureStakes(mature.length);
        }
    }, [user]);

    useEffect(() => {
        const _getProposals = async () => {
            try {
                let activeVotes = 0;
                const proposals = await getProposals();
                const now = Date.now() / 1000;
                proposals.forEach((p) => {
                    if (p.start <= now && p.end >= now) activeVotes++;
                });
                setActiveVotes(activeVotes);
            } catch (err) {
                console.error(err);
            }
        };
        _getProposals();
    }, []);

    return (
        <List classes={{ root: classes.list }}>
            {menuItems.map(({ name, pathname, Icon, external, subItems }, index) => {
                let title = name;
                let tooltip = null;
                let disabled = false;
                const isHidden = HIDDEN_CHAIN_MENU_ITEMS.includes(chain);

                if (title === 'NFTs' && isHidden) return null;
                if (title === 'Accelerator' && isHidden) return null;
                if (title === 'Collider' && isHidden) return null;
                if (title === 'Stake') {
                    if (isHidden) return null;
                    if (matureStakes > 0) {
                        title += ` (${matureStakes})`;
                        tooltip = `You have ${matureStakes} matured stakes to withdraw!`;
                    }
                } else if (title === 'Voting' && activeVotes > 0) {
                    title += ` (${activeVotes})`;
                    tooltip = `There ${activeVotes === 1 ? 'is' : 'are'} ${activeVotes} active proposal${
                        activeVotes === 1 ? '' : 's'
                    } to vote on!`;
                } else if (title === 'Launch' && activeLaunches > 0) {
                    title += ` (${activeLaunches})`;
                    tooltip = `There ${activeLaunches === 1 ? 'is' : 'are'} ${activeLaunches} current Launch${
                        activeLaunches === 1 ? '' : 'es'
                    }!`;

                    if (subItems && subItems.length > 0) {
                        const subItemCopy = [...subItems];
                        const launchItem = subItemCopy.find((i) => i.name === 'Launches');
                        if (launchItem) launchItem.name = `${launchItem.name} (${activeLaunches})`;

                        const pledgeItem = subItemCopy.find((i) => i.name === 'Pledge');
                        if (pledgeItem) pledgeItem.hidden = isHidden;
                    }
                } else if (title === 'Utilities') {
                    if (subItems && subItems.length > 0) {
                        const subItemCopy = [...subItems];
                        const item = subItemCopy.find((i) => i.name === 'Add AXN Token');
                        if (item) item.hidden = isHidden;
                    }
                }

                return (
                    <NavigationItem
                        Icon={Icon}
                        name={title}
                        tooltip={tooltip}
                        disabled={disabled}
                        external={external}
                        pathname={pathname}
                        key={`navitem-${index}`}
                        subItems={subItems || []}
                        location={window.location}
                    />
                );
            })}
        </List>
    );
};

export default MenuItems;
