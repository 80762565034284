import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    bgBlueteal: {
        background: theme._gradients.acceleratorCard,
        color: 'white',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    root: {
        padding: theme.spacing(2),
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    statCardTitle: {
        fontSize: 20,
        color: 'white',
        fontWeight: 700,
        fontFamily: '"Heebo", sans-serif',
    },
    statCardValue: {
        display: 'block',
        fontSize: 20,
        color: 'white',
        fontWeight: 700,
        fontFamily: '"Heebo", sans-serif',
        textAlign: 'center',
        padding: '12px 0px',
    },
    statCardNote: {
        display: 'block',
        fontSize: 14,
        color: 'white',
        fontWeight: 500,
        fontFamily: '"Heebo", sans-serif',
        textAlign: 'center',
    },
    statIconView: {
        marginRight: 12,
        padding: 6,
        borderRadius: '50%',
    },
    statIcon: {
        color: 'white',
        height: 30,
        width: 30,
    },
    selectMenu: {
        borderRadius: 5,
        cursor: 'pointer',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255, 255, 255, .15)',
    },
    selectText: {
        color: theme.brand.NormalText,
    },
    selectMenuItem: {
        padding: theme.spacing(1),
        fontWeight: 500,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .2)',
        },
    },
    connect: {
        opacity: 0.4,
        fontSize: '0.8rem',
        marginLeft: theme.spacing(0.5),
    },
    column: {
        [theme.breakpoints.only('xs')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
}));

export default useStyles;
