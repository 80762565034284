import React from 'react';
import moment from 'moment';
import useStyles from './styles';
import Button from 'components/Button';
import SlideUp from 'components/Transitions/SlideUp';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const translation = {
    current: 'In Progress',
    past: 'Completed',
};

const LaunchModal = ({ open, onClose, launch }) => {
    const classes = useStyles();
    const openURL = (url) => window.open(url, '_blank');
    const buildImageURL = (endpoint) => `https://axion.network/${endpoint}`;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            disableScrollLock
            TransitionComponent={SlideUp}
            classes={{ paper: classes.paper, root: classes.root }}
        >
            <img alt="" src={buildImageURL(launch?.['al-modalimage'])} />
            <div className={classes.dialogTitle}>
                <Typography variant="h2">
                    {launch?.title}
                    {launch?.['al-type'] && ` - ${launch?.['al-type']}`}
                </Typography>
                <Typography variant="h4">
                    Status: {translation[launch?.['al-state']]}
                    {translation[launch?.['al-state']] === 'Completed' && ` ${moment(launch?.['launchMS']).format('MMMM D, YYYY')}`}
                </Typography>
            </div>

            <DialogContent className={classes.contentContainer}>
                {launch?.['al-modaldescription'].split('\n').map((desc) => (
                    <Typography className={classes.descriptionItem} key={desc}>
                        {desc}
                    </Typography>
                ))}
                {launch?.link && (
                    <Typography>
                        For more information about this launch,{' '}
                        <span className={classes.textLink} onClick={() => openURL(launch?.link)}>
                            click here
                        </span>
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default LaunchModal;
