import BN from 'bn.js';
import axios from 'axios';
import Contract from './contract';
import { base, routes } from 'api/requests';
import { BURN_ADDRESS, CONTRACT_INFO, _1E18, _1E16 } from 'utils/variables';

class Axion extends Contract {
    async getInfo() {
        let totalStaked5555 = 0;
        let axnPerETH = [0, 0],
            usdPerAXN = 0;
        let bpdShares = ['0', '0', '0', '0', '0'];

        // Get contract stats
        const [stats, totalSupply, burned] = await Promise.all([
            this.contracts.StakeManager.getStatFields(),
            this.contracts.AXN.totalSupply(),
            this.contracts.AXN.balanceOf(BURN_ADDRESS),
        ]);

        // try to get total staked 5555
        try {
            totalStaked5555 = await axios(base + routes.getCoin).then((res) => res.data.store.Staked.totalAxnStaked5555);
        } catch (err) {
            console.error('Cannot get totalStaked5555', err.message);
        }

        // Try to get prices (if there's liquidity)
        try {
            [axnPerETH, usdPerAXN] = await Promise.all([
                this.contracts.SwapRouter.getAmountsOut(_1E16, [
                    CONTRACT_INFO.Tokens.WETH,
                    CONTRACT_INFO.Tokens.USDC,
                    CONTRACT_INFO.AXN.ADDRESS,
                ]),
                this.contracts.PriceGetter.getPrice(CONTRACT_INFO.AXN.ADDRESS, 18),
            ]);
        } catch (err) {
            console.error('Cannot get current ETH and AXN prices due to getAmountsOut error. Liquidity???', err.message);
        }

        // Try to get BPD
        try {
            bpdShares = await this.contracts.BPD.getBpdShares();
        } catch (err) {
            console.error('Cannot get BPD shares', err.message);
        }

        return {
            bpdShares,
            axnPerETH: axnPerETH[2] / 1e16, // 1e16 is the amount of ETH we're using to get the price. Not using 1e18 because price impact was too high at the time. This is a hack fix.
            usdPerAXN: usdPerAXN / 1e18,
            totalStaked5555: totalStaked5555 / 1e18,
            totalStaked: stats.totalStakedAmount / 1e6,
            totalShares: stats.sharesTotalSupply / 1e6,
            totalSupply: totalSupply / 1e18,
            totalBurned: burned / 1e18,
        };
    }

    async balance() {
        const balance = await this.contracts.AXN.balanceOf(this.account);
        let BNBalance = new BN(balance.toString());
        return BNBalance;
    }

    totalBurned() {
        return this.contracts.AXN.balanceOf(BURN_ADDRESS);
    }
}

export default Axion;
