import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme._shadows.card,
        backgroundColor: theme.brand.CardBG,
        borderRadius: 4,
        overflow: 'hidden',
    },
    glossy: {
        backdropFilter: 'blur(6px)',
        backgroundColor: 'rgba(255, 255, 255, .05)',
        boxShadow: '4px 4px 12px rgb(0 0 0 / 20%)',
    },
    glossyButton: {
        backgroundColor: 'rgba(255, 255, 255, .10)',
    },
}));

export default useStyles;
