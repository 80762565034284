import React, { useContext, useState } from 'react';
import useStyles from './styles';
import { vote } from 'utils/voting';
import clsx from 'clsx';
import Button from 'components/Button';
import { CircularProgress, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ContractContext } from 'web3/WagmiListener';
import { setSnackbar } from 'redux/actions/snackbar';

const Vote = ({ proposal, refresh, results, shares }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [choices, setChoices] = useState([]);
    const [loading, setLoading] = useState('');
    const { web3, wallet } = useContext(ContractContext);

    const _voteMulti = async () => {
        try {
            setLoading(true);
            await vote(proposal.id, choices, wallet.account, web3);
            refresh();
            setChoices([]);
            dispatch(
                setSnackbar({
                    message: `Successfully voted for ${choices.length} options`,
                    type: 'success',
                }),
            );
        } catch (err) {
            dispatch(setSnackbar({ message: err.message }));
        } finally {
            setLoading(null);
        }
    };

    const performVote = async (idx, choice) => {
        if (loading) return;
        if (shares === 0) {
            dispatch(setSnackbar({ message: 'Error placing vote: You have no voting power.' }));
            return;
        }

        if (web3) {
            const isMulti = proposal.type === 'approval';
            if (isMulti) {
                if (choices.includes(idx)) setChoices(choices.filter((c) => c !== idx));
                else setChoices([...choices, idx]);
                return;
            } else if (results.valid && Object.keys(results.voters).length !== 0 && results.voters[wallet?.account]?.vote === choice)
                return;

            try {
                setLoading(idx);
                await vote(proposal.id, isMulti ? choices : idx, wallet.account, web3);
                refresh();
                dispatch(setSnackbar({ message: `Successfully voted for: ${choice}`, type: 'success' }));
            } catch (err) {
                dispatch(setSnackbar({ message: err.message }));
            } finally {
                setLoading(null);
            }
        }
    };

    return (
        <React.Fragment>
            <div className={classes.voteContainer}>
                {proposal.choices.map((choice, idx) => {
                    let includesChoice = false;
                    if (proposal.type === 'approval')
                        includesChoice = results.valid && results?.voters[wallet?.account]?.vote?.split(', ')?.includes(choice);
                    else includesChoice = results.valid && results?.voters[wallet?.account]?.vote === choice;

                    return (
                        <div
                            key={idx}
                            onClick={() => performVote(idx + 1, choice)}
                            className={clsx(classes.voteOption, {
                                [classes.disabled]: !web3 || shares === 0,
                                [classes.selected]:
                                    proposal.type === 'approval'
                                        ? choices.includes(idx + 1) || (includesChoice && choices.length === 0)
                                        : includesChoice,
                            })}
                        >
                            {loading === idx + 1 ? <CircularProgress size={25} /> : <Typography>{choice}</Typography>}
                        </div>
                    );
                })}
            </div>
            {proposal.type === 'approval' && choices.length > 0 && (
                <div className={classes.multiVoteContainer}>
                    This is a multiple choice vote. Click on an option to toggle it.
                    <br />
                    You can choose as many options as you'd like. Your total shares will be added to each option you choose.
                    <br />
                    <br />
                    <Button loading={loading} variant="outlined" onClick={() => _voteMulti()}>
                        Submit {choices.length}
                        {choices.length === 1 ? ' vote' : ' votes'}
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
};

export default Vote;
