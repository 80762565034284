import Contract from './contract';
import abi from '../abi.json';
import Bignumber from 'bignumber.js';
import { store } from 'redux/config';
import { MaxUint256 } from '@ethersproject/constants';
import { addTx, completedTx } from 'redux/actions/transactions';
import { SECONDS_IN_DAY, CONTRACT_INFO, ETHEREUM_TOKEN, OTC_ADDRESS } from 'utils/variables';
import BlockChain from 'web3/Blockchain';

const ERC20 = abi.ERC20;

const dispatch = store.dispatch;

class Accelerator extends Contract {
    async getInfo() {
        const [acceleratorData, otcUSDC, utilityNFTAmounts] = await Promise.all([
            this.contracts.Accelerator.getData(),
            this.getContract(ERC20.ABI, CONTRACT_INFO.Tokens.USDC).balanceOf(OTC_ADDRESS),
            this.contracts.NFTCollection.totalSupplyAll(),
        ]);

        const [
            start,
            seconds,
            maxBoughtPerDay,
            currentDay,
            minStakeDays,
            maxMint,
            mintedAmount,
            axnPercentage,
            wbtcPercentage,
            recipientPercentage,
        ] = acceleratorData;

        const standardPhoenixCount = +utilityNFTAmounts[CONTRACT_INFO.NFTCollection.PHOENIX_STANDARD];
        const silverPhoenixCount = +utilityNFTAmounts[CONTRACT_INFO.NFTCollection.PHOENIX_SILVER];
        const goldPhoenixCount = +utilityNFTAmounts[CONTRACT_INFO.NFTCollection.PHOENIX_GOLD];

        const boughtToday = await this.contracts.Accelerator.bought(currentDay || 1);
        const cur = boughtToday / 1e18;
        const max = maxBoughtPerDay / 1e18;

        return {
            start: +start,
            seconds: +seconds,
            currentDay: +currentDay,

            maxMint: maxMint / 1e18,
            mintedAmount: mintedAmount / 1e18,

            maxBoughtPerDay: max,
            minStakeDays: +minStakeDays,
            otcUsdcAllTime: otcUSDC / 1e6,

            bonusActive: cur < max,
            bonusRemaining: Math.max(0, max - cur),

            percentages: [+axnPercentage, +wbtcPercentage, +recipientPercentage],

            phoenixAmounts: {
                standard: standardPhoenixCount,
                silver: silverPhoenixCount,
                gold: goldPhoenixCount,
            },
        };
    }

    /**
     * This function is used to buy and stake axion with a token
     *
     * @param {object} token - The token to stake
     * @param {string} amount - Amount of token to stake
     * @param {number} days - # of days to stake
     * @param {boolean} usingDivs - boolean to determine if the token is coming from users divs
     */
    async buyAndStake(token, amount, days, usingDivs) {
        const isMatic = token.address === ETHEREUM_TOKEN.tokenAddress;
        const isBTC = token.address === CONTRACT_INFO.Tokens.WBTC;
        const isWETH = token.address === CONTRACT_INFO.Tokens.WETH;

        const _amount = new Bignumber(amount * 10 ** token.decimals);

        /** If allowance is less then amount, send approval request */
        if (!isMatic && !usingDivs) {
            const tokenContract = this.getContract(ERC20.ABI, token.address);
            const allowance = new Bignumber(await tokenContract.allowance(this.account, this.contracts.Accelerator.address));

            if (allowance.minus(_amount).isNegative()) throw new Error('approval');
        }

        // Calculate amountOutMin for wBTC and AXN
        const pathAXN = isMatic
            ? [CONTRACT_INFO.Tokens.MATIC, CONTRACT_INFO.Tokens.USDC, CONTRACT_INFO.AXN.ADDRESS]
            : isWETH
            ? [CONTRACT_INFO.Tokens.WETH, CONTRACT_INFO.Tokens.USDC, CONTRACT_INFO.AXN.ADDRESS]
            : [/*token.address,*/ CONTRACT_INFO.Tokens.USDC, CONTRACT_INFO.AXN.ADDRESS];
        const pathBTC = [
            token.address,
            CONTRACT_INFO.Tokens.WBTC,
        ]; /*isMatic ? [CONTRACT_INFO.Tokens.MATIC, CONTRACT_INFO.Tokens.WETH, CONTRACT_INFO.Tokens.WBTC] : isWETH ? [CONTRACT_INFO.Tokens.WETH, CONTRACT_INFO.Tokens.WBTC] :[token.address, CONTRACT_INFO.Tokens.WBTC];*/

        let percentages = [];
        let accelerator = BlockChain.accelerator;

        if (!accelerator) {
            percentages = await Promise.all([
                this.contracts.Accelerator.splitAmounts(0),
                this.contracts.Accelerator.splitAmounts(1),
                this.contracts.Accelerator.splitAmounts(2),
            ]);
        } else percentages = BlockChain.accelerator.percentages;

        // [axn, wbtc, recipient]
        const amounts = [];

        // Reduce by percentages
        percentages.forEach((p) => amounts.push(_amount.times(p / 100)));

        const slippages = {
            axn: await this.getAmountOutMin(amounts[0], pathAXN, 5), //VCA_SLIPPAGES.AXN),
            wbtc: isBTC ? 0 : await this.getAmountOutMinBackup(amounts[1], pathBTC, 1), //VCA_SLIPPAGES.WBTC),
        };

        const deadline = Math.round(new Date().getTime() / 1000) + SECONDS_IN_DAY;

        /** Send Transaction */

        const method = usingDivs ? 'axionBuyAndStakeWithDivs' : 'axionBuyAndStake';

        let tx;
        if (isMatic) {
            tx = await this.contracts.Accelerator.connect(this.signer).axionBuyAndStakeEth(slippages.axn, slippages.wbtc, deadline, days, {
                value: _amount.toFixed(0),
            });
        } else {
            tx = await this.contracts.Accelerator.connect(this.signer)[method](
                token.address,
                _amount.toFixed(0),
                slippages.axn,
                slippages.wbtc,
                deadline,
                days,
            );
        }

        dispatch(addTx({ id: tx.hash, description: `Axion Accelerator (${token.symbol})` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * This function is used to aprove a token to be
     * spent by the Accelerator contract
     * @param {object} token - The token object
     */
    async approve(token) {
        const contract = this.getContract(ERC20.ABI, token.address);
        let tx = await contract.connect(this.signer).approve(this.contracts.Accelerator.address, MaxUint256);

        dispatch(addTx({ id: tx.hash, description: `Token Approval (${token.symbol})` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Determine the amountOutMin from token -> (w)eth -> axn
     * @param {string} amount - The amount
     * @param {string[]} path - The addresses of the path
     * @param {number} slippage - The slippage to use.
     */
    async getAmountOutMin(amount, path, slippage) {
        let amounts;
        try {
            amounts = await this.contracts.SwapRouter.getAmountsOut(amount.toFixed(0), path);
        } catch (e) {
            amounts = new Array(path.length).fill(0);
            console.log('Error getting slippages:', e, amounts);
        }

        return this.reduceAmountByPercent(amounts[path.length - 1], slippage);
    }

    /**
     * Determine the amountOutMin from token -> (w)eth -> axn using the backup router
     * @param {string} amount - The amount
     * @param {string[]} path - The addresses of the path
     * @param {number} slippage - The slippage to use.
     */
    async getAmountOutMinBackup(amount, path, slippage) {
        let amounts;
        try {
            amounts = await this.contracts.SwapRouterBackup.getAmountsOut(amount.toFixed(0), path);
        } catch (e) {
            amounts = new Array(path.length).fill(0);
            console.log('Error getting backup slippages:', e, amounts);
        }

        return this.reduceAmountByPercent(amounts[path.length - 1], slippage);
    }
}

export default Accelerator;
