import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    frame: {
        width: '100%',
        height: 800,
        border: 0,
    },
    button: {
        marginTop: 20,
    },
    bold: {
        fontWeight: 'bold',
    },
    link: {
        display: 'inline',
        cursor: 'pointer',
    },
    badge: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: '8px 16px',
        borderRadius: 30,
        fontSize: 14,
    },
    badgeComplete: {
        backgroundColor: theme.mode === 'supernova' ? '#725746' : theme.brand.Tropaz,
    },
    badgeActive: {
        backgroundColor: theme.brand.JavaGreen,
    },
    badgePending: {
        backgroundColor: theme.brand.GrayChateau,
    },
    card: {
        background: theme.brand.CardBG,
        color: theme.brand.NormalText,
    },
    cardHeader: {
        padding: 15,
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    cardBody: {
        padding: '2%',
        display: 'flex',
        flexDirection: 'column',
    },
    p: {
        marginBottom: '1em',
    },
    a: {
        display: 'inline',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export default useStyles;
