import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from 'redux/actions/snackbar';
import { Snackbar, Alert } from '@mui/material';

const AUTO_HIDE_TIME = 5 * 1000; // 5 seconds

const StyledSnackbar = (props) => {
    const { snackbar } = useSelector(({ snackbar }) => ({ snackbar }));
    const dispatch = useDispatch();

    const { type = 'error', vertical = 'top', horizontal = 'right', message, link, duration = AUTO_HIDE_TIME } = snackbar;

    function handleClose() {
        dispatch(setSnackbar({ message: '' }));
    }

    if (!message) return null;

    // Replace some error messages with more user friendly ones
    let messageToShow = message;
    if (message.toLowerCase().includes('execution reverted'))
        messageToShow = `Error: Not enough MATIC for gas. Please ensure your wallet has some MATIC.`;
    else if (message.toLowerCase().includes('transaction was not mined within 50 blocks'))
        messageToShow = `Your transaction is taking longer than usual due to a below average gas price that was set. Check Polygonscan for more info.`;
    else if (message.toLowerCase().includes('insufficient funds for transfer'))
        messageToShow = `Unable to calculate gas. Please enter a slightly smaller amount, or try again after refreshing the page.`;

    return (
        <Snackbar
            open={true}
            anchorOrigin={{ vertical, horizontal }}
            autoHideDuration={duration}
            onClose={handleClose}
            style={{ zIndex: 99999 }}
        >
            <Alert severity={type}>
                {messageToShow}
                {link && (
                    <>
                        &nbsp;
                        <span onClick={() => window.open(link)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                            Learn More
                        </span>
                    </>
                )}
            </Alert>
        </Snackbar>
    );
};

export default StyledSnackbar;
