import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    button: {
        marginBottom: 15,
    },
    open: {
        cursor: 'pointer',
        fontSize: '1em',
    },
    infoGrid: {
        marginBottom: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
    card: {
        background: theme.brand.CardBG,
        color: theme.brand.NormalText,
    },
    cardHeader: {
        padding: 15,
        borderBottom: `1px solid ${theme.brand.border}`,
        textAlign: 'left',
    },
    cardBody: {
        padding: '2%',
        display: 'flex',
        flexDirection: 'column',
    },
    p: {
        marginBottom: '1em',
    },
    a: {
        display: 'inline',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    img: {
        width: '100%',
    },
    voteCount: {
        fontSize: '0.95rem',
    },
    address: {
        cursor: 'pointer',
    },
}));

export default useStyles;
