import React, { useContext, useState } from 'react';
import useStyles from './styles';
import Button from 'components/Button';
import { CircularProgress, Divider, LinearProgress, Typography } from '@mui/material';
import { VOTE_SHARE_CUTOFF_SECONDS } from 'utils/variables';
import { useAccount } from 'wagmi';

const Results = ({ choices, results, refresh }) => {
    const classes = useStyles();
    const [refetching, setRefetching] = useState(false);
    const { isConnected } = useAccount;

    const refetchResults = async () => {
        try {
            setRefetching(true);
            await refresh();
        } catch (e) {
            console.error(e);
        } finally {
            setRefetching(false);
        }
    };

    return (
        <div className={classes.resultContainer}>
            {!isConnected && <Typography align="center">Please connect your wallet to calculate and view the final results.</Typography>}

            {results.done && results.valid
                ? Object.keys(results.totals).map((total) => {
                      const percentage = (results.totals[total] / results.totalVotedShares) * 100;
                      return (
                          <div key={total} className={classes.result}>
                              <Typography variant="h4" align="center">
                                  {[total]}
                              </Typography>
                              <Typography variant="h6" align="center">
                                  {results.totals[total].toLocaleString()}{' '}
                                  {results?.proposal?.end > VOTE_SHARE_CUTOFF_SECONDS ? ' Votes' : ' Shares'} ({percentage.toFixed(1)}%)
                              </Typography>
                              <LinearProgress variant="determinate" value={percentage} classes={{ root: classes.progress }} />
                          </div>
                      );
                  })
                : !results.done && (
                      <center>
                          <CircularProgress />
                      </center>
                  )}

            {/* Handle Result that has partially failed */}
            {results.done && results.valid && results.incompleteResults && (
                <div>
                    {Object.keys(results.voters).length > 0 && (
                        <>
                            <Divider />
                            <br />
                        </>
                    )}

                    <Typography className={classes.errorText}>
                        Due to an intermittent issue with a third-party provider, we were unable to retrieve the number of shares for each
                        voter.{' '}
                        {Object.keys(results.voters).length > 0 &&
                            `The results above only consist of data from ${Object.keys(results.voters).length} addresses. `}
                        Press the button below to try again, or check back later.
                    </Typography>
                    <br />
                    <Button loading={refetching} disabled={refetching} fullWidth override="translucent" onClick={refetchResults}>
                        Try Again
                    </Button>
                </div>
            )}
        </div>
    );
};

export default Results;
