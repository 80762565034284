import React, { useEffect, useState } from 'react';

import axios from 'axios';
import useStyles from './styles';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material';
import graphStyle from 'utils/apex-bare-line-style';

const LiveTicker = (props) => {
    const [values, setValues] = useState(new Array(121));

    const classes = useStyles(props);
    const theme = useTheme(props);

    useEffect(() => {
        const _getData = async () => {
            const chart = await axios('https://api.coingecko.com/api/v3/coins/axion/market_chart?vs_currency=usd&days=6');
            setValues(chart.data.prices.slice().reverse());
        };

        _getData();
    }, []);

    const indexes = [96, 72, 48, 24, 10, 8, 6, 0];
    const labels = ['96h', '72h', '48h', '24h', '10h', '8h', '6h', '10m'];
    const series = indexes.map((index) => values?.[index]?.[1]?.toFixed?.(5) ?? 0.0);

    const min = Math.min(...series);
    const max = Math.max(...series);

    const _graph = graphStyle({
        theme,
        series,
        max,
        min,
        labels,
        xFormatter: (index) => {
            if (index === labels.length) return labels[index - 1];
            return `${labels[index - 1]} ago`;
        },
    });

    return (
        <div className={classes.root}>
            <Chart options={_graph.options} series={_graph.series} type="area" height={90} />
        </div>
    );
};

export default LiveTicker;
