import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    atom: {
        width: 20,
    },
    openMenuIcon: {
        width: 20,
        color: theme.brand.NormalText,
        transition: 'all 0.15s linear',
    },
    logoIcon: {
        height: 75,
        width: 75,
        marginBottom: theme.spacing(2),
    },
    whiteText: {
        color: 'rgba(255,255,255,0.7)',
    },
    list: {
        padding: 0,
        width: '100%',
        position: 'relative',
    },
    listItem: {
        paddingTop: theme.spacing(1.75),
        paddingBottom: theme.spacing(1.75),
        '@media (max-height:800px)': {
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
        },
    },
    subMenuListItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    hover: {
        transition: '.4s ease-out',
        justifyContent: 'center',
        '&:hover': {
            '&:after': {
                transition: '.4s ease-out',
                content: '""',
                position: 'absolute',
                right: 0,
                height: '50%',
                width: '3px',
                borderRadius: '1em',
                backgroundColor: theme.palette.primary.main,
                background: 'transparent',
                color: 'transparent',
            },
        },
    },
    menuIcons: {
        transition: '.4s ease-out',
        fontSize: 20,
        marginLeft: 6,
        color: theme.brand.NormalText,
    },
    activeText: {
        color: `${theme.palette.primary.main} !important`,
    },
    disabledItem: {
        cursor: 'not-allwoed',
        opacity: 0.1,
    },
    activeItem: {
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            height: '50%',
            width: '3px',
            borderRadius: '1em',
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    activeIcon: {
        color: theme.palette.primary.main,
    },
    top: {
        width: '100%',
    },
    supernovaIcon: {
        filter: 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))',
    },
    subMenu: {
        backgroundColor: theme.brand.NavigationBackground,
        filter: 'brightness(80%)',
    },

    open: {
        transform: 'rotateZ(180deg)',
    },
}));

export default useStyles;
