import React from 'react';

import clsx from 'classnames';
import useStyles from './styles';
import { Box } from '@mui/material';

const Card = ({ children, className, glossy = true, button, ...props }) => {
    const classes = useStyles(props);

    return (
        <Box
            className={clsx(className, {
                [classes.root]: true,
                [classes.glossy]: glossy,
                [classes.glossyButton]: glossy && button,
            })}
            {...props}
        >
            {children}
        </Box>
    );
};

export default Card;
