import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontWeight: 100,
        fontSize: '1.6rem',
        color: theme.brand.SecondaryText,

        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
            textAlign: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1rem',
            textAlign: 'center',
        },
    },
    supernovaGlow: {
        filter: 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))',
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
}));

export default useStyles;
