import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WagmiProvider } from 'wagmi';
import { polygon } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Icon from '../assets/images/axion-logo.svg';

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'eee568e4f453ec6c2038d603363b2749';

// 2. Create wagmiConfig
const metadata = {
    name: 'Axion Network',
    description: 'Axion Network Staking | Accelerator | Collider | NFTs',
    url: 'https://axion.network', // origin must match your domain & subdomain
    icons: [Icon],
};

const chains = [polygon];
const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
});

// 3. Create modal
createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
});

export function Web3ModalWrapper({ children }) {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
    );
}
