import React, { useCallback, useContext, useState } from 'react';
import useStyles from './styles';
import useCheckKYC from 'hooks/useCheckKYC';
import { Badge, Typography, useMediaQuery } from '@mui/material';
import { ContractContext } from 'web3/WagmiListener';
import { KYC_FORM_API, MANAGE_KYC } from 'utils/variables';
import { ReactComponent as PoweredBySkrt } from 'assets/images/powered-by-sekuritance.svg';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import ConnectWalletModal from 'components/Modals/ConnectWalletModal/ConnectWalletModal';

const translation = {
    NOT_VERIFIED: 'KYC Not Verified',
    VERIFIED: 'KYC Verified',
    PENDING: 'KYC Pending Review',
    IN_REVIEW: 'KYC Pending Review',
    DECLINED: 'KYC Declined',
    FAILED: 'KYC Declined',
};

const KycStatus = () => {
    const classes = useStyles();
    const kycStatus = useCheckKYC();
    const { wallet } = useContext(ContractContext);
    const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const [connection, setConnection] = useState({ open: false, connect: null });

    const openKycForm = useCallback(() => {
        window.open(`${KYC_FORM_API(kycStatus.wallet.address, kycStatus.wallet.chain)}`, '_blank');
    }, [kycStatus]);

    const openManageKYC = () => {
        window.open(MANAGE_KYC, '_blank');
    };

    return (
        <div className={classes.root}>
            <div className={classes.titleContainer}>
                <div className={classes.statusContainer}>
                    <Typography className={classes.title} variant="h3">
                        KYC Status
                    </Typography>
                    <div>
                        <Badge
                            variant="dot"
                            color="primary"
                            classes={{
                                badge: classNames(classes.badge, {
                                    [classes.hidden]: kycStatus.status === '',
                                    [classes.green]: kycStatus.status === 'VERIFIED',
                                    // [classes.transparent]: kycStatus.status === 'NOT_VERIFIED',
                                    [classes.yellow]: kycStatus.status === 'PENDING' || kycStatus.status === 'IN_REVIEW',
                                    [classes.red]:
                                        kycStatus.status === 'DECLINED' ||
                                        kycStatus.status === 'FAILED' ||
                                        kycStatus.status === 'NOT_VERIFIED',
                                }),
                            }}
                        />
                    </div>
                </div>
                <PoweredBySkrt className={classes.skrtLogo} />
            </div>
            <br />
            <Typography>
                KYC (Know Your Customer) is required by law for some types of launch. Users can complete their KYC application with our
                partner, Sekuritance.
            </Typography>
            {(kycStatus.status === 'NOT_VERIFIED' || kycStatus.status === '') && (
                <Typography className={classes.skrtBlue}>
                    Axion will never know your KYC details, we only have access to your wallet address.
                </Typography>
            )}
            {wallet?.account && kycStatus?.status && (
                <React.Fragment>
                    <Typography className={classes.status}>
                        {`Status of wallet ${wallet.account.shortenAddress()}: ${translation[kycStatus.status]}`}
                    </Typography>
                    {kycStatus.status === 'NOT_VERIFIED' && (
                        <Button
                            size="small"
                            fullWidth={isXs}
                            onClick={openKycForm}
                            override="translucent"
                            classes={{ root: classes.actionBtn }}
                        >
                            Apply for KYC Now
                        </Button>
                    )}
                    {kycStatus.status === 'IN_REVIEW' ||
                        (kycStatus.status === 'PENDING' && (
                            <Typography className={classes.yellowText}>
                                Please wait for Sekuritance to finish reviewing your KYC application.
                            </Typography>
                        ))}
                    {kycStatus.status === 'VERIFIED' ||
                        kycStatus.status === 'DECLINED' ||
                        (kycStatus.status === 'FAILED' && (
                            <React.Fragment>
                                {kycStatus.status !== 'VERIFIED' && (
                                    <Typography className={classes.yellowText}>Please contact Sekuritance for more information.</Typography>
                                )}
                                <Button
                                    size="small"
                                    fullWidth={isXs}
                                    onClick={openManageKYC}
                                    override="translucent"
                                    classes={{ root: classes.actionBtn }}
                                >
                                    Manage KYC at Sekuritance
                                </Button>
                            </React.Fragment>
                        ))}
                </React.Fragment>
            )}
            {!wallet?.account && (
                <React.Fragment>
                    <Button
                        size="small"
                        fullWidth={isXs}
                        override="translucent"
                        onClick={() => setConnection({ open: true, connect: wallet.connect })}
                        classes={{ root: classes.actionBtn }}
                    >
                        Connect Wallet
                    </Button>
                    <ConnectWalletModal
                        open={connection.open}
                        onClose={() => setConnection({ open: false })}
                        connect={connection.connect}
                        wallet={wallet}
                    />
                </React.Fragment>
            )}
        </div>
    );
};

export default KycStatus;
