import React, { useContext, useMemo, useState } from 'react';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setChain } from 'redux/actions/chain';
import { ETH_CHAIN, MATIC_CHAIN, BSC_CHAIN } from 'utils/variables';
import { MenuItem, TextField, Typography } from '@mui/material';
import { bsc, mainnet, polygon } from 'wagmi/chains';
import { useSwitchChain } from 'wagmi';

const NetworkCard = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { chain } = useSelector((store) => store);
    const { switchChain } = useSwitchChain();

    const chainName = useMemo(() => {
        if (chain === MATIC_CHAIN) return 'Polygon';
        if (chain === ETH_CHAIN) return 'Ethereum';
        if (chain === BSC_CHAIN) return 'BSC';
        else return 'Unknown';
    }, [chain]);

    const onChange = async (e) => {
        if (e.target.value === 'Ethereum') {
            await switchChain({ chainId: mainnet.id });
            dispatch(setChain(ETH_CHAIN));
        } else if (e.target.value === 'Polygon') {
            await switchChain({ chainId: polygon.id });
            dispatch(setChain(MATIC_CHAIN));
        } else if (e.target.value === 'BSC') {
            await switchChain({ chainId: bsc.id });
            dispatch(setChain(BSC_CHAIN));
        }
    };

    return (
        <div className={classes.column}>
            <TextField
                select
                fullWidth
                margin="dense"
                label="Network"
                variant="outlined"
                SelectProps={{
                    open: open,
                    value: chainName,
                    onChange: onChange,
                    onOpen: () => setOpen(true),
                    onClose: () => setOpen(false),
                    MenuProps: {
                        disableScrollLock: true,
                        classes: { paper: classes.selectMenu },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                    },
                }}
            >
                {['Polygon', 'Ethereum', 'BSC'].map((network, idx) => (
                    <MenuItem disabled={network === chainName} value={network} key={idx}>
                        <Typography>{network}</Typography>
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default NetworkCard;
