import React from 'react';
import useStyles from './styles';
import { Typography } from '@mui/material';
import KycStatus from 'components/KycStatusBox/KycStatus';

const PageTitle = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography className={classes.title} variant="h1">
                    Axion Launch
                </Typography>
                <Typography className={classes.body} variant="h6">
                    With Axion Launch, Max Stakers get to enjoy the exclusive benefits of curated Launches perfect for our community. Each
                    Axion Launch is unique; some may offer airdrops, some may grant unique bonuses, some may allow for significant discounts
                    over purchase price. For more information,{' '}
                    <span className={classes.link} onClick={() => window.open(`https://axion.network/axion-launch`)}>
                        click here
                    </span>
                    .
                </Typography>
            </div>
            <div className={classes.container}>
                <KycStatus />
            </div>
        </div>
    );
};

export default PageTitle;
