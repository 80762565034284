import clsx from 'clsx';
import React from 'react';
import useStyles from './styles';
import { Tooltip } from '@mui/material';
import useGasPrice from 'hooks/useGasPrice';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const GasTracker = () => {
    const gwei = useGasPrice();
    const classes = useStyles();

    if (!gwei) return null;
    return (
        <Tooltip
            arrow
            title={
                <span>
                    Click to view more gas price information.
                    <br />
                    <span style={{ fontSize: '0.75rem' }}>Powered by polygonscan.com APIs.</span>
                </span>
            }
        >
            <div
                onClick={() => window.open('https://polygonscan.com/gastracker')}
                className={clsx(classes.profile, classes.gas, {
                    [classes.gasGreen]: gwei < 100,
                    [classes.gasOrange]: gwei < 200 && gwei >= 100,
                    [classes.gasRed]: gwei >= 200,
                })}
            >
                <LocalGasStationIcon className={classes.mr6} />
                {gwei} Gwei
            </div>
        </Tooltip>
    );
};

export default GasTracker;
