import { makeStyles } from '@mui/styles';
import { drawerWidth } from 'global-styles';

// #2a333a navdrawer
const useStyles = makeStyles((theme) => ({
    drawer: {
        whiteSpace: 'nowrap',
        backgroundColor: theme.brand.NavigationBackground,
        backgroundImage: 'unset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: drawerWidth,
        zIndex: 6,
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 15px rgba(255, 143, 0, 0.8))' : '',
    },
    wallet: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: `16px 0px`,
        backgroundColor: theme.brand.NavigationDrawerHeader,
        width: '100%',
        paddingBottom: 0,
    },
    ticker: {
        paddingTop: theme.spacing(1),
        backgroundColor: theme.brand.NavigationDrawerHeader,
    },
    atom: {
        width: 20,
    },
    logoIcon: {
        height: 75,
        width: 75,
        marginBottom: theme.spacing(2),
    },
    whiteText: {
        color: 'rgba(255,255,255,0.7)',
    },
    profile: {
        color: 'rgba(255,255,255,0.7)',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        padding: '8px 16px',
        borderRadius: 30,
        fontSize: 14,
        backgroundColor: theme.brand.axion,
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer',
    },
    disconnect: {
        cursor: 'pointer',
        marginLeft: '7px',
        fontSize: '1.2em',

        '&:hover': {
            transform: 'rotate(90deg)',
            transition: 'transform 0.3s ease',
        },
    },
    list: {
        padding: 0,
        width: '100%',
        position: 'relative',
    },
    listItem: {
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        '@media (max-height:800px)': {
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
        },
    },
    subMenuListItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    hover: {
        transition: '.4s ease-out',
        justifyContent: 'center',
        '&:hover': {
            '&:after': {
                transition: '.4s ease-out',
                content: '""',
                position: 'absolute',
                right: 0,
                height: '50%',
                width: '4px',
                borderRadius: '1em',
                backgroundColor: theme.palette.primary.main,
                background: 'transparent',
                color: 'transparent',
            },
        },
    },
    menuIcons: {
        transition: '.4s ease-out',
        fontSize: 20,
        marginLeft: 6,
        color: theme.brand.NormalText,
    },
    activeText: {
        color: `${theme.palette.primary.main} !important`,
    },
    disabledItem: {
        cursor: 'not-allwoed',
        opacity: 0.1,
    },
    activeItem: {
        '&:after': {
            content: '""',
            position: 'absolute',
            right: 0,
            height: '50%',
            width: '4px',
            borderRadius: '1em',
            backgroundColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    activeIcon: {
        color: theme.palette.primary.main,
    },
    top: {
        width: '100%',
    },
    bottom: {
        boxSizing: 'border-box',
        flexDirection: 'column',
        borderTop: `1px solid ${theme.brand.border}`,
        padding: '16px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    bottomRow: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',

        width: '100%',
        justifyContent: 'space-between',
    },
    bottomPart: {
        width: '100%',
    },
    bridgeButton: {
        justifyContent: 'center',
        transition: 'background-color 0.5s ease',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.08)',
        },
    },
    social: {
        fontSize: 22,
        color: theme.palette.primary.main,
    },
    modeIcon: {
        fontSize: 20,
        color: theme.brand.NormalText,
    },
    modeActive: {
        color: theme.palette.primary.main,
    },
    mr12: {
        marginRight: theme.spacing(1.5),
    },
    mr6: {
        marginRight: theme.spacing(0.75),
    },
    pendingTxContainer: {
        padding: 0,
        margin: 0,
        maxWidth: '75%',
    },
    selectMenu: {
        borderRadius: 5,
        cursor: 'pointer',
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255, 255, 255, .15)',
    },
    selectText: {
        color: theme.brand.NormalText,
    },
    selectMenuItem: {
        padding: theme.spacing(1),
        fontWeight: 500,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .2)',
        },
    },
    supernovaIcon: {
        filter: 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))',
    },
    subMenu: {
        backgroundColor: theme.brand.NavigationBackground,
        filter: 'brightness(80%)',
    },
    themeSwitcher: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

export default useStyles;
