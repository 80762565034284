import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    bgBlueteal: {
        background: theme._gradients.blueTeal,
        color: 'white',
    },
    acceleratorBG: {
        background: theme._gradients.acceleratorCard,
        color: 'white',
    },
    bgUniswap: {
        background: theme._gradients.uniswap,
        color: 'white',
    },
    primary: {
        background: `${theme.brand.axion}9f`,
    },
    root: {
        padding: '20px 0',
        width: '100%',
        cursor: 'pointer',
        userSelect: 'none',
        transition: '0.3s',
        '&:hover': {
            transform: 'translateY(-0.15em)',
            filter: 'brightness(0.80)',
        },
    },
    supernovaGlow: {
        filter: theme.mode === 'supernova' ? 'drop-shadow(0px 0px 5px rgba(255, 143, 0, 0.8))' : '',
    },
    statCardTitle: {
        fontSize: 20,
        color: theme.brand.NormalText,
        fontWeight: 500,
        marginBottom: 5,
        fontFamily: '"Heebo", sans-serif',
    },
    statCardValue: {
        display: 'block',
        fontSize: 20,
        color: 'white',
        fontWeight: 700,
        fontFamily: '"Heebo", sans-serif',
        textAlign: 'center',
        padding: 0,
    },
    statCardNote: {
        color: theme.brand.NormalText,
        fontWeight: 300,
        fontSize: '0.8rem',
        fontFamily: '"Heebo", sans-serif',
        [theme.breakpoints.only('md')]: {
            fontSize: '0.9em',
        },
    },
    statIconView: {
        marginRight: 12,
        padding: 6,
        borderRadius: '50%',
    },
    statIcon: {
        height: 40,
        width: 40,
        paddingLeft: 10,
        color: theme.mode === 'dark' ? 'white' : theme.brand.accent,
    },
    info: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
    subStat: {
        color: '#FFF',
        fontWeight: 500,
    },
    mainStat: {
        color: '#FFF',
    },
    white: {
        color: 'white',
    },
}));

export default useStyles;
