import React from 'react';

// Components
import { Link } from 'react-router-dom';
import { AppBar, Typography, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
// Assets
import AxionLogo from 'assets/images/axion-logo.png';
// Use
import useStyles from './styles';

export default function NavigationBar({ onMenuClicked }) {
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <div className={classes.header}>
                <div className={classes.left}>
                    <Link to="/">
                        <img src={AxionLogo} className={classes.logo} alt="logo" />
                    </Link>
                    <Typography variant="h5" style={{ color: 'white' }}>
                        Axion Staking
                    </Typography>
                </div>
                <div className={classes.right}>
                    <IconButton className={classes.menu} onClick={onMenuClicked}>
                        <Menu className={classes.menuIcon} />
                    </IconButton>
                </div>
            </div>
        </AppBar>
    );
}
