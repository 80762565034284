import React from 'react';
// Modules
import clsx from 'classnames';
import { Button, CircularProgress, useTheme } from '@mui/material';
import useStyles from './styles';

export default function LoadingButton(props) {
    const classes = useStyles();
    const { loading, children, override, ...rest } = props;
    const theme = useTheme();

    return (
        <Button
            {...rest}
            className={clsx(classes.root, {
                [classes[override]]: !!override,
            })}
        >
            {loading ? (
                <CircularProgress
                    style={{ height: 20, width: 20 }}
                    classes={{
                        root: clsx(classes.loader, {
                            [classes.white]: rest.variant === 'contained',
                            [classes.blue]: theme.mode === 'dark' && rest.variant === 'contained',
                        }),
                    }}
                />
            ) : (
                children
            )}
        </Button>
    );
}
