import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { get as getEvents } from 'redux/actions/events';
import { get as getLaunches } from 'redux/actions/launches';
import { get as getAccelerator } from 'redux/actions/accelerator';
/** Routes */
import Dashboard from 'containers/Dashboard/Dashboard';
import Voting from 'containers/Voting/Voting';
import Tools from 'containers/Tools/Tools';
import Launch from 'containers/Launch/Launch';
import Pledge from 'containers/Pledge/Pledge';
import Vesting from 'containers/Vesting/Vesting';
/** Components */
import Navigation from 'components/Navigation/Navigation';
import CookiConsent from 'components/CookieConsent/CookieConsent';
import Snackbar from 'components/Snackbar/Snackbar';
import AlertBar from 'components/AlertBar/AlertBar';
import Notification from 'components/Notification/Notification';
import RouteAnalytics from 'components/util/RouteAnalytics';
import { Alert, Typography } from '@mui/material';

const NavigationRoute = (props) => {
    return (
        <>
            <CookiConsent />
            <Snackbar />
            <AlertBar />
            <Notification />
            <RouteAnalytics />
            <Navigation>
                <Routes>
                    {/* <Route path="" element={<Dashboard />} /> */}
                    <Route path="" element={<Voting />} />
                    <Route path="voting" element={<Voting />} />
                    <Route path="vote/:id" element={<Voting />} />
                    <Route path="tools" element={<Tools />} />
                    <Route path="launch" element={<Launch />} />
                    <Route path="pledge" element={<Pledge />} />
                    <Route path="vesting" element={<Vesting />} />
                </Routes>
            </Navigation>
        </>
    );
};

const Router = (props) => {
    const dispatch = useDispatch();
    const { chain } = useSelector((store) => store);
    const [widgetCreated, setWidgetCreated] = useState(false);

    const _getEvents = () => {
        dispatch(getEvents());
    };
    const _getLaunches = () => {
        dispatch(getLaunches());
    };
    const _getAccelerator = () => {
        dispatch(getAccelerator());
    };

    useEffect(() => {
        _getEvents();
        _getLaunches();
        _getAccelerator();
        // _setupBridgeWidget();

        // Auto refresh data
        const eventInterval = setInterval(() => {
            _getEvents();
        }, 60000 * 5); // 5 mins
        const priceInterval = setInterval(() => {
            _getAccelerator();
        }, 60000); // 1 minute

        return () => {
            clearInterval(eventInterval);
            clearInterval(priceInterval);
        };
    }, []);

    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<NavigationRoute />} />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default Router;
