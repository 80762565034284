import BN from 'bn.js';
import ABI from '../abi.json';
import { CONTRACT_INFO, _1E18, ETHEREUM_TOKEN, DEFAULT_GAS_PRICE, BSC_CHAIN, BSC_RPC } from 'utils/variables';
import { ethers } from 'ethers';

class Contract {
    constructor(params) {
        this.contracts = params?.contracts;
        this.address = params?.address;
        this.account = params?.address;
        this.provider = params?.provider;
        this.signer = params?.signer;
    }
    /**
     * Get token information from any ERC20 token address.
     *
     * @param {string} address - The address of the ERC20 token
     * @returns {Promise<any>} An object containing the token name, symbol and decimals
     */
    async getTokenInfo(address) {
        if (address === '0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF') {
            return { name: 'Ethereum', symbol: 'ETH', decimals: 18 };
        }

        const tokenContract = this.getContract(ABI.ERC20.ABI, address);
        const [name, symbol, decimals] = await Promise.all([tokenContract.name(), tokenContract.symbol(), tokenContract.decimals()]);

        return { name, symbol, decimals: +decimals };
    }

    /**
     *
     * @param {*} abi
     * @param {*} address
     */
    getContract(abi, address) {
        return new ethers.Contract(address, abi, this.provider);
    }

    /** END initializer functions
     * ******************************************
     * ******************************************
     * ******************************************
     * ******************************************
     */

    /** Class Inheritance functions
     * ******************************************
     * ******************************************
     * ******************************************
     * ******************************************
     */

    /**
     * Check a specific spender's address to see if it has access to see if
     * Axion is allowed to be spent by that contract.
     *
     * @param {string} amount - The amount of the bid in wei (10^18)
     * @param {string} address - The spender address (Staking contract, Mining contract, etc.)
     * @returns {Promise<boolean>} A flag determining if the user is approved.
     */
    async checkApproval(amount, address) {
        const allowance = new BN(await Contract.AXN.allowance(this.account, address));
        return !allowance.sub(new BN(amount)).isNeg();
    }

    /**
     *
     * @param {*} amount
     * @param {*} mineAddress
     * @param {*} lpTokenAddress
     */
    async isTokenApproved(amount, approvedFor, tokenAddress) {
        const token = this.getContract(ABI.ERC20.ABI, tokenAddress);
        const allowance = await token.allowance(this.account, approvedFor);

        const allow = new BN(allowance);
        const allowed = allow.sub(amount);
        return !allowed.isNeg();
    }

    /** Get token information from any ERC20 token address.
     *
     * @param {string} address - The address of the ERC20 token
     * @param {number} chain - The chain of the ERC20 token
     * @returns {Promise<any>} An object containing the token name, symbol and decimals
     */
    async getTokenInfo(address, chain) {
        let tokenContract = this.getContract(ABI.ERC20.ABI, address);
        if (chain === BSC_CHAIN) {
            const bscProvider = new ethers.providers.JsonRpcProvider(BSC_RPC);
            tokenContract = new ethers.Contract(address, ABI.ERC20.ABI, bscProvider);
        }
        const [name, symbol, decimals] = await Promise.all([tokenContract.name(), tokenContract.symbol(), tokenContract.decimals()]);

        return { name, symbol, decimals: +decimals };
    }

    /** Get token information from any ERC20 token address on Ethereum.
     *
     * @param {string} address - The address of the ERC20 token
     * @returns {Promise<any>} An object containing the token name, symbol and decimals
     */
    async getTokenInfoETH(address) {
        const tokenContract = this.getContract(ABI.ERC20.ABI, address);
        const [name, symbol, decimals] = await Promise.all([tokenContract.name(), tokenContract.symbol(), tokenContract.decimals()]);

        return { name, symbol, decimals: +decimals };
    }

    /** Get the balance of a token for the current user
     *
     * @param {string} address - The address of the ERC20 token
     * @returns {Promise<any>} The current balance
     */
    getBalanceOf(address) {
        if (address === ETHEREUM_TOKEN.tokenAddress) return this.provider.getBalance(this.account);

        const tokenContract = this.getContract(ABI.ERC20.ABI, address);
        return tokenContract.balanceOf(this.account);
    }

    /** Get the allowance of a token for the current user
     *
     * @param {string} token - The address of the ERC20 token
     * @param {string} spender - The address of the spender contract
     * @returns {Promise<any>} The current allowance
     */
    getAllowanceOf(token, spender) {
        if (token === ETHEREUM_TOKEN.tokenAddress) return 1e18;

        const tokenContract = this.getContract(ABI.ERC20.ABI, token);
        return tokenContract.allowance(this.account, spender);
    }

    async getWethToTokenAmountsOut(amount, tokenAddress) {
        if (tokenAddress === CONTRACT_INFO.Tokens.WETH) {
            return [_1E18, _1E18];
        }

        return this.contracts.SwapRouter.getAmountsOut(amount, [CONTRACT_INFO.Tokens.WETH, tokenAddress]);
    }

    async getTokenToUsdcAmountsOutAsync(tokenAddress, amount) {
        const outputAmounts = await this.contracts.SwapRouter.getAmountsOut(amount, [tokenAddress, CONTRACT_INFO.Tokens.USDC]);

        return outputAmounts[1];
    }

    /**
     * Helper function for that
     * reduces amount passed in by percent (e.g: for slippage)
     *
     * @param {string} amount - The amount to reduce
     * @param {number} percent - The percent to reduce by
     * @returns {string>} The reduced amount
     */
    reduceAmountByPercent(amount, percent) {
        return new BN(amount.toString()).muln((100 - percent) / 100).toString();
    }

    /**
     * TODO: get this
     * Helper function that returns the current gas price
     * @returns {Promise<string>} The current gas price
     */
    async getGasPrice() {
        if (this.provider?.getGasPrice) {
            try {
                const gas = await this.provider.getGasPrice();
                if (parseInt(gas) < parseInt(DEFAULT_GAS_PRICE)) return DEFAULT_GAS_PRICE;

                // increase gas price by 5%
                return new BN(gas).muln(105).divn(100).toString();
            } catch (err) {
                return DEFAULT_GAS_PRICE;
            }
        } else return DEFAULT_GAS_PRICE;
    }
}

export default Contract;
