import Contract from './contract';
import { store } from 'redux/config';
import { DEFAULT_GAS_PRICE } from 'utils/variables';
import { addTx, completedTx } from 'redux/actions/transactions';
import { ethers } from 'ethers';

const dispatch = store.dispatch;

class Utility extends Contract {
    async getInfo() {
        const [nameCost, splitCost] = await Promise.all([
            this.contracts.StakeUtilities.nameCost(),
            this.contracts.StakeUtilities.splitCost(),
        ]);

        return {
            nameCost: nameCost / 1e18,
            splitCost: splitCost / 1e18,
        };
    }

    /**
     * Change the name of a stake.
     *
     * @param {string} stakeID - The ID of the stake to rename
     * @param {string} name - The new name of the stake
     * @param {object} costs - An object that contains all utility pricing
     */
    async rename(stakeID, name, costs) {
        let value = costs['nameCost'];

        let tx = await this.contracts.StakeUtilities.connect(this.signer).nameStake(name, stakeID, {
            value: ethers.utils.parseEther(`${value}`).toString(),
        });

        dispatch(addTx({ id: tx.hash, description: `Rename Stake` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }

    /**
     * Split a stake by a percentage
     *
     * @param {string} stakeID - The ID of the stake to rename
     * @param {number} percent - The percent to split by.
     * @param {object} costs - An object that contains all utility pricing
     */
    async split(stakeID, percent, costs) {
        let value = costs['splitCost'];

        let tx = await this.contracts.StakeUtilities.connect(this.signer).splitStake(stakeID, percent, {
            value: ethers.utils.parseEther(`${value}`).toString(),
        });

        dispatch(addTx({ id: tx.hash, description: `Split Stake (${percent}/${100 - percent})` }));
        await tx.wait();
        dispatch(completedTx(tx.hash));
    }
}

export default Utility;
