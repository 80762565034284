import { makeStyles } from '@mui/styles';
import bg from 'assets/images/bg4.jpeg';
import bgsupernova from 'assets/images/AxionSupernovaBG1.jpg';
import bgCollider from 'assets/images/AxionColliderBackground.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        userSelect: 'none',
        zIndex: -1,
        filter: 'brightness(0.5)',

        '&:before': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 1,
            position: 'absolute',
            backgroundSize: 'cover',
        },
    },
    positionBottom: {
        backgroundPosition: 'bottom',
    },
    bg4: {
        '&:before': {
            backgroundImage: `url(${theme.mode === 'dark' ? bg : bgsupernova})`,
        },
    },
    bg1: {
        '&:before': {
            opacity: 1,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${bgCollider})`,
        },
    },
}));

export default useStyles;
