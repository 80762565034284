import { makeStyles } from '@mui/styles';
import { NavigationBarHeight } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: NavigationBarHeight,
        backgroundImage: 'unset',
        display: 'flex',
        flexDirection: 'row',
        zIndex: 9,
        backgroundColor: theme.brand.NavigationDrawerHeader,
    },

    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        color: 'white',
        overflow: 'visible',
        padding: '0px 16px',
    },
    left: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 1,
    },
    logo: {
        height: 30,
        width: 30,
        marginRight: 10,
    },

    menu: {
        color: 'white',
    },
}));

export default useStyles;
