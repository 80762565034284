import { sample } from 'lodash';
import ABI from 'web3/abi.json';
import discord from 'assets/images/discord';
import { FaTelegramPlane } from 'react-icons/fa';
import wBtc from 'assets/images/coinIcons/wbtc_w.svg';
import { GrTwitter, GrYoutube } from 'react-icons/gr';
import axionWhite from 'assets/images/axion-white.png';
import EthereumWhite from 'assets/images/ethereum-white.png';

export const ETH_CHAIN = 1;
export const BSC_CHAIN = 56;
export const TESTING = false; //window.location.hostname !== 'stake.axion.network';

/** Mainnet Changer  */
export const MATIC_CHAIN = 137;
export const MAIN_NETWORK = 'mainnet';

export const TEST_CHAIN = 137;
export const TEST_NETWORK = 'testnet';

export const CHAIN = TESTING ? TEST_CHAIN : MATIC_CHAIN;
export const NETWORK = TESTING ? TEST_NETWORK : MAIN_NETWORK;

export const SECONDS_IN_DAY = 86400;
export const START_OF_CONTRACTS = 1620592125;

export const APR = 8;
export const MAX_APR = 47;
export const MINT_COST = 20;
export const DEX = 'ApeSwap';
export const REF_KEY = 'referral';
export const SLIPPAGE_PERCENT = 0.5;
export const RENAME_MAX_LENGTH = 32;
export const RECIPIENT_PERCENT = 20;
export const MIN_STAKE_AMOUNT = 50000;
export const CONTRACT_INFO = ABI[NETWORK];
export const INTERCOM_APP_ID = 'mxvbj3bq';
export const ACCELERATOR_START = 1630394346;
export const DEFAULT_GAS_PRICE = '40000000000'; // 40 gwei
export const AIRDROP_KEY = 'last-matic-airdrop';
export const _1E18 = Math.pow(10, 18).toString();
export const _1E16 = Math.pow(10, 16).toString();
export const GOOGLE_ANALYTICS_ID = 'G-V9YJHGYSLT';
export const VOTE_SHARE_CUTOFF_SECONDS = 1643317749;
export const CLAIMED_AXION = 246698808344.141614613906096687;
export const UNCLAIMED_AXION = 3301191655.858385386093903313;
export const PERIOD = (SECONDS_IN_DAY * 350) / SECONDS_IN_DAY;
export const POLYGONSCAN_API = '2PQGTZP5YZGY5ZY53UD7GZNSNGXG6VXKII';
export const AVAILABLE_DAYS_AFTER_END = (SECONDS_IN_DAY * 14) / SECONDS_IN_DAY;

// Addresses
export const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2';
export const OTC_ADDRESS = '0x35d868814918E6A32487b1bC18e4d1DFE9844e60';
export const BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const ETH_ADDRESS = '0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF';

// URLS
export const IPFS_API = 'https://ipfs.io/ipfs/';
export const BACKUP_IPFS_API = 'https://infura-ipfs.io/ipfs/';
export const POLYGON_RPC = 'https://polygon-rpc.com/';
export const BSC_RPC = 'https://bsc-dataseed.binance.org/';
export const NEWS_API = 'https://axion.network/data/articles';
export const LAUNCH_API = 'https://axion.network/data/axion-launch';
export const UPDATES_API = 'https://axion.network/data/staking-platform-data';
export const COLLIDER_COLLECTION = `https://opensea.io/collection/axion-collider`;
export const FAUCET_API = 'https://polygon-api.axion.network/api/faucet/request-polygon/';
export const GALAXY_INFO_URL = 'https://support.axion.network/en/collections/3512400-galaxy-stake-nfts';
export const SWAP_URL = `https://apeswap.finance/swap?outputCurrency=${CONTRACT_INFO.AXN.ADDRESS}&theme=dark`;
export const HAS_VOTED_URL = `https://us-central1-tokyo-kingdom-306418.cloudfunctions.net/has-address-voted`;
export const UTILITY_NFT_OPENSEA = `https://opensea.io/assets/matic/${CONTRACT_INFO.NFTCollection.ADDRESS}/`;
export const PARTICLE_NFT_OPENSEA = `https://opensea.io/assets/matic/${CONTRACT_INFO.AxionColliderNFT.ADDRESS}/`;
export const GALAXY_NFT_OPENSEA = `https://opensea.io/assets/matic/${CONTRACT_INFO.StakeToken.ADDRESS}/`;
export const POLYGONSCAN_GAS_API = `https://api.polygonscan.com/api?module=proxy&action=eth_gasPrice&apikey=${POLYGONSCAN_API}`;
export const MAIL_CHIMP_URL = `https://network.us1.list-manage.com/subscribe/post?u=8ec4f8ea812fd56894225ce62&amp;id=67a16606a5`;
export const AXN_BURN_ADDRESS_LINK = `https://polygonscan.com/token/0x839f1a22a59eaaf26c85958712ab32f80fea23d9?a=0x000000000000000000000000000000000000dead`;
export const AUCTION_CLAIM_LINK =
    'https://docs.google.com/forms/d/e/1FAIpQLSdn4ctKGIc_3KNqXHhuVhHiaas_KoMFX-SmN69BpJyFkPNXAg/viewform?usp=pp_url&entry.2038859891=';

export const MANAGE_KYC = 'https://portal.sekuritance.com/';
export const KYC_API = 'https://us-central1-tokyo-kingdom-306418.cloudfunctions.net/launch-kyc-check';
export const KYC_FORM_API = (address, chain) =>
    `https://launch.sekuritance.com/launch/054eeeac-b2a1-498e-82b7-457f18d582c1?address=${address}&chain=${chain}`;

// DAO/Voting
export const DAO_SPACE_NAME = 'axiondao.eth';
export const DAO_LINK = `https://vote.axion.network/`;
export const DAO_VOTE_API = 'https://hub.snapshot.org/api/message';
export const DAO_GQL_ENDPOINT = `https://hub.snapshot.org/graphql`;
export const DAO_GET_PROPOSALS = `https://hub.snapshot.page/api/${DAO_SPACE_NAME}/proposals`;
export const DAO_GET_PROPOSAL = `https://hub.snapshot.page/api/${DAO_SPACE_NAME}/proposal`;
export const DAO_HIDDEN_VOTES = [
    'QmQXMUkuxjoWkxNYXN7DnnsEuqEebv8DiA5T81tuiS3Mdb',
    '0x3470ac34d0d88399ed203b7c7fe021d704cd08651b4fce195ad80066e1994d7d',
];

// Objects
export const INFURA_URL = `https://mainnet.infura.io/v3/${sample([
    '55cc3a5483a643c184fe62add7f8be45',
    '6de5b5f18b584ee8919193522ce28bf9',
    'c6d84621d508429a90f0892b25dde244',
    'a70607932b6e4743b0a4d9524528e0a9',
    '1cbd3fac50074512b8fbc9e45776eb74',
])}`;

export const ETH_RPC = `https://rpc.ankr.com/eth`;

export const CONFETTI_SETTINGS = {
    angle: 360,
    spread: 360,
    lifetime: 500,
    elementCount: 130,
};

export const SPLIT_IO_CONFIG = {
    core: {
        authorizationKey: NETWORK === 'mainnet' ? 'v20latu2b63f4f7kvtq89jirnejl744t359o' : 'gupf913fc6e69lbftj8dpr3moddh7gg99mqs',
        key: 'key',
    },
};

export const TOKENS_DATA = {
    wbtc: {
        icon: wBtc,
        tooltip: `Wrapped Bitcoin delivers the power of Bitcoin with the flexibility of an ERC20 token on the Polygon blockchain. (Click to learn more!)`,
        url: 'https://wbtc.network/',
    },
    eth: {
        icon: EthereumWhite,
        tooltip: `ETH is digital, global money. It's the currency of Ethereum apps. (Click to learn more!)`,
        url: 'https://ethereum.org/',
    },
    axn: {
        icon: axionWhite,
        tooltip: 'Axion that is earned from the sell tax. (Click to be taken to the Axion website!)',
        url: 'https://axion.network/',
    },
};

export const ETHEREUM_TOKEN = {
    tokenDecimals: 18,
    tokenSymbol: 'ETH',
    tokenName: 'Ethereum',
    tokenAddress: ETH_ADDRESS,
};

export const STAKE_TYPES = {
    ACTIVE_STAKES: 'Active Stakes',
    MATURED_STAKES: 'Matured Stakes',
    COMPLETED_STAKES: 'Completed Stakes',
};

export const CONNECTOR_SETTINGS = {
    walletconnect: {
        rpcUrl: POLYGON_RPC,
        bridge: 'https://polygon.bridge.walletconnect.org',
    },
};

export const BPD_AMOUNTS = [5000000000, 7500000000, 10000000000, 12500000000, 15000000000];

export const BPD_TIMES = [1635490929, 1665730929, 1695970929, 1726210929, 1756450929];

export const WIDGET = {
    width: 540,
    height: 630,
    overlay: false,
    autoShowTime: 0,
    closable: true,
    page: 'deposit',
    position: 'center',
    network: 'mainnet',
    target: '#bridge-widget',
    appName: 'AxionStakingPlatform',
};

export const SOCIAL_LINKS = [
    { title: 'Join our community on Telegram', link: 'https://t.me/axionofficial', Icon: FaTelegramPlane },
    { title: 'Join our community on Discord', link: 'https://axion.network/discord', Icon: discord },
    { title: 'Follow us on Twitter', link: 'https://twitter.com/axion_network', Icon: GrTwitter },
    { title: 'Subscribe to our YouTube channel', link: 'https://www.youtube.com/c/AxionOfficial', Icon: GrYoutube },
];
