import React, { useState, useEffect, useContext } from 'react';
import { cloneDeep } from 'lodash';
import useStyles from './styles';
import Countdown from '../Countdown';
import Button from 'components/Button';
import { Tooltip } from '@mui/material';
import { BSC_CHAIN, ETH_CHAIN } from 'utils/variables';
import { setChain } from 'redux/actions/chain';
import CustomTable from 'components/CustomTable';
import { openToken } from 'utils/open-etherscan';
import { ContractContext } from 'web3/WagmiListener';
import { useDispatch, useSelector } from 'react-redux';
import matamaskIcon from 'assets/images/metamask-fox.svg';
import polygonscanIcon from 'assets/images/polygonscanlogo.png';
import bscScanIcon from 'assets/images/BscScan-logo-circle.webp';
import HorizontalCell from 'components/CustomTable/HorizontalCell';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import etherscanIcon from 'assets/images/etherscan-logo-light-circle.svg';
import { addToMetamask, requestChainSwitchBSC, requestChainSwitchETH, addBSCToMetamask } from 'utils/add-to-metamask';

const TableHeaders = ['Token', 'Contract', 'Vesting Round', 'Allocation', 'Bonus', 'Next Unlock', 'Available to Claim', 'Claimed', ''];

const translation = {
    Token: 'token',
    Bonus: 'bonusAmount',
    'Vesting Round': 'name',
    Allocation: 'allocation',
    'Next Unlock': 'timeUntilNext',
    'Available to Claim': 'available',
    Claimed: 'claimed',
};

const ClaimVestedTokens = ({ data = [], refreshData, loadingPledges }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { chain } = useSelector((store) => store);
    const { library, user, userLoading } = useContext(ContractContext);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(null);
    const [loadingBonus, setLoadingBonus] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState({ order: 'asc', orderBy: 'Token' });

    useEffect(() => {
        let ordered;
        const clonedStakes = cloneDeep(data);

        const key = translation[sort.orderBy];

        if (key === 'token' || key === 'name')
            ordered = clonedStakes.sort((a, b) => (sort.order === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key])));
        else ordered = clonedStakes.sort((a, b) => (sort.order === 'asc' ? b[key] - a[key] : a[key] - b[key]));

        setItems(ordered.splice(perPage * page, perPage));
    }, [data, sort, page, perPage]);

    // Pagination
    function handleChangePage(e, page) {
        setPage(page);
    }
    function handleChangeRows(e) {
        setPerPage(e.target.value);
    }
    function handleOnSort(newOrder, index) {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    }

    const handleWithdraw = async (item, type = 'withdraw') => {
        if (type === 'withdraw') setLoading(item.name);
        else setLoadingBonus(item.name);

        try {
            await library.Vesting[type](item);
            await refreshData();
        } catch (err) {
            console.log(err);
        } finally {
            if (type === 'withdraw') setLoading(null);
            else setLoadingBonus(null);
        }
    };

    const switchChain = async (network) => {
        try {
            if (network === ETH_CHAIN) {
                await requestChainSwitchETH();
                dispatch(setChain(ETH_CHAIN));
            } else if (network === BSC_CHAIN) {
                await requestChainSwitchBSC();
                dispatch(setChain(BSC_CHAIN));
            }
        } catch (err) {
            if (network === BSC_CHAIN) {
                await addBSCToMetamask();
                await requestChainSwitchBSC();
                dispatch(setChain(BSC_CHAIN));
            } else console.log(err);
        }
    };

    const getIcon = (chainID) => {
        if (chainID === ETH_CHAIN) return etherscanIcon;
        else if (chainID === BSC_CHAIN) return bscScanIcon;
        else return polygonscanIcon;
    };

    const getExplorerName = (chainID) => {
        if (chainID === ETH_CHAIN) return 'Etherscan';
        else if (chainID === BSC_CHAIN) return 'BSCscan';
        else return 'Polygonscan';
    };

    return (
        <CustomTable
            headers={TableHeaders}
            classes={{ root: classes.table }}
            count={data.length}
            page={page}
            pagination={false}
            onSort={handleOnSort}
            onChangePage={handleChangePage}
            onChangeRows={handleChangeRows}
            initialOrderIndex={0}
            rowsPerPage={perPage}
            emptyMessage={
                user
                    ? loadingPledges
                        ? 'Loading your data...'
                        : 'No vested tokens.'
                    : userLoading
                    ? 'Loading wallet info...'
                    : 'Please connect your wallet.'
            }
            rows={items.map((item) => {
                if (!item) return { columns: SkeletonTableRow({ count: 7 }) };
                return {
                    columns: [
                        `${item.token} (${item.symbol})`,
                        <HorizontalCell>
                            <div className={classes.iconContainer}>
                                <Tooltip title={`View on ${getExplorerName(item._network)}`} arrow>
                                    <img
                                        alt="etherscan"
                                        className={classes.icon}
                                        src={getIcon(item._network)}
                                        onClick={() => openToken(item.address, item._network)}
                                    />
                                </Tooltip>
                                <Tooltip title="Add to Metamask" arrow>
                                    <img
                                        alt="metamask"
                                        className={classes.icon}
                                        src={matamaskIcon}
                                        onClick={() => addToMetamask(item.address, item.symbol, item.decimals, item.token)}
                                    />
                                </Tooltip>
                            </div>
                        </HorizontalCell>,
                        item.name,
                        item.allocation.numberWithCommas(),
                        item.bonusAmount.numberWithCommas(),
                        Date.now() / 1000 > item.timeUntilNext || isNaN(item.timeUntilNext) ? (
                            '00:00:00'
                        ) : (
                            <Countdown onEnd={() => refreshData()} end={item.timeUntilNext * 1000} unlockSeconds={item.unlockDelay} />
                        ),
                        item.available.numberWithCommas(),
                        item.claimed.numberWithCommas(),
                        <HorizontalCell>
                            {item._network !== chain && (
                                <Button size="small" override="translucent" onClick={() => switchChain(item._network)}>
                                    Switch Networks
                                </Button>
                            )}

                            {item._network === chain && (
                                <>
                                    <Button
                                        size="small"
                                        override="translucent"
                                        loading={loading === item.name}
                                        onClick={() => handleWithdraw(item)}
                                        disabled={item.available === 0 || loading === item.name || !user}
                                    >
                                        {!user
                                            ? 'Connect Wallet'
                                            : Date.now() > item.startTime * 1000
                                            ? item.claimed === item.allocation
                                                ? 'Tokens Claimed'
                                                : 'Claim'
                                            : 'Waiting for Start'}
                                    </Button>

                                    {user && Date.now() / 1000 > item.bonusUnlockTime && item.bonusAmount > 0 && item.withdrawals > 0 && (
                                        <>
                                            &nbsp;
                                            <Button
                                                size="small"
                                                override="translucent"
                                                loading={loadingBonus === item.name}
                                                onClick={() => handleWithdraw(item, 'withdrawBonus')}
                                                disabled={loadingBonus === item.name || item.bonusWithdrawn}
                                            >
                                                {item.bonusWithdrawn ? 'Bonus Claimed' : 'Claim Bonus'}
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </HorizontalCell>,
                    ],
                };
            })}
        />
    );
};

export default ClaimVestedTokens;
