import { makeStyles } from '@mui/styles';
import { drawerWidth, NavigationBarHeight } from 'global-styles';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        marginLeft: drawerWidth,
        maxWidth: '100%',

        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginTop: NavigationBarHeight,
            paddingBottom: 0,
        },
    },
    connect: {
        backgroundColor: theme.brand.accent,
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    message: {
        display: 'flex',
        fontSize: '0.9rem',
        alignItems: 'center',
        color: theme.brand.NavigationBackground,
    },
    icon: {
        fontSize: '1.2rem',
        marginRight: theme.spacing(1),
        color: theme.brand.NavigationBackground,
    },
    closeIcon: {
        fontSize: '1.2rem',
        cursor: 'pointer',
        color: theme.brand.NavigationBackground,
    },
    link: {
        display: 'flex',
        cursor: 'pointer',
        fontWeight: '500',
        fontSize: '0.8rem',
        alignItems: 'center',
        textDecoration: 'underline',
        marginLeft: theme.spacing(1),
    },
}));

export default useStyles;
