import React, { useEffect, useState, useRef } from 'react';
import useStyles from './styles';
import { getVotesWithoutShares } from 'utils/voting';
import Card from 'components/Card';
import { cloneDeep } from 'lodash';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomTable from 'components/CustomTable';
import SkeletonTableRow from 'components/Skeleton/SkeletonTableRow';
import { openAddress } from 'utils/open-etherscan';

const TableHeaders = ['Address', 'Vote'];
const Translation = {
    Address: 'address',
    Vote: 'vote',
};

const VotersTable = ({ proposal, shareCounts }) => {
    const theme = useTheme();
    const classes = useStyles();
    const mounted = useRef(false);
    const condenseAddress = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = useState(0);
    const [votes, setVotes] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [tableData, setTableData] = useState(null);
    const [sort, setSort] = useState({ order: 'desc', orderBy: 'Shares' });

    useEffect(() => {
        if (Object.keys(shareCounts ?? {}).length > 0 && votes.length > 0) {
            let v = [...votes];
            Object.entries(shareCounts).forEach(([addr, { shares }]) => {
                const idx = v.findIndex((vote) => vote.address === addr);
                if (idx !== -1) v[idx].shares = shares;
            });
            setVotes(v);
        }
        // eslint-disable-next-line
    }, [shareCounts]);

    useEffect(() => {
        mounted.current = true;
        const _getVotersWithoutShares = async () => {
            const choices = proposal.choices;
            const votes = await getVotesWithoutShares(proposal.id, choices);
            setVotes(votes);
        };

        _getVotersWithoutShares();

        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (votes.length > 0) {
            const clonedVotes = cloneDeep(votes);
            const key = Translation[sort.orderBy];

            const newTableData = clonedVotes.sort((a, b) => {
                if (typeof a[key] === 'string') return sort.order === 'asc' ? b[key].localeCompare(a[key]) : a[key].localeCompare(b[key]);
                return sort.order === 'asc' ? a[key] - b[key] : b[key] - a[key];
            });

            setTableData(newTableData.splice(perPage * page, perPage));
        }
    }, [sort, page, perPage, votes]);

    const handleChangePage = (e, page) => {
        mounted && setPage(page);
    };

    const handleChangeRows = (e) => {
        mounted && setPerPage(e.target.value);
    };

    const handleOnSort = (newOrder, index) => {
        setSort({
            order: newOrder,
            orderBy: TableHeaders[index],
        });
    };

    return (
        <Card classes={{ root: classes.card }}>
            <div className={classes.cardHeader}>
                <Typography variant="h2">
                    Votes <span className={classes.voteCount}>({votes.length})</span>
                </Typography>
            </div>
            <CustomTable
                headers={TableHeaders}
                count={votes.length}
                page={page}
                onSort={handleOnSort}
                onChangePage={handleChangePage}
                onChangeRows={handleChangeRows}
                initialOrderIndex={2}
                rowsPerPage={perPage}
                rows={(!tableData ? Array.from(new Array(5)) : tableData).map((vote, index) => {
                    if (!vote)
                        return {
                            columns: SkeletonTableRow({
                                count: 2,
                            }),
                        };

                    return {
                        columns: [
                            <span className={classes.address} onClick={() => openAddress(vote.address)}>
                                {condenseAddress ? `${vote.address.substring(0, 6)}...${vote.address.slice(-4)}` : vote.address}
                            </span>,
                            Array.isArray(vote.vote) ? vote.vote.join(', ') : vote.vote,
                        ],
                    };
                })}
            />
        </Card>
    );
};

export default VotersTable;
