import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'classnames';
import useStyles from './styles';
import Page from 'components/Page';
import Meta from 'components/util/Meta';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import PageTitle from './PageTitle/PageTitle';
import { Grid, Typography } from '@mui/material';
import Background from 'components/Background/Background';
import DayCountdown from 'components/DayCountdown/DayCountdown';
import LaunchModal from '../../components/Modals/LaunchModal/LaunchModal';
import { ContractContext } from 'web3/WagmiListener';
import Button from 'components/Button/Button';

const Launch = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { user } = useContext(ContractContext);
    const { launches } = useSelector((store) => store);
    const [launchModal, setLaunchModal] = useState({ launch: null, open: false });
    const [_launches, setLaunches] = useState({ upcoming: [], past: [], current: [] });
    const buildImageURL = (endpoint) => `https://axion.network/${endpoint}`;

    useEffect(() => {
        if (launches?.launches && launches.launches.length > 0) {
            const l = launches.launches || [];
            setLaunches({
                current: l.filter((l) => l['al-state'] === 'current'),
                upcoming: l.filter((l) => l['al-state'] === 'upcoming'),
                past: l.filter((l) => l['al-state'] === 'past').sort((a, b) => b['launchMS'] - a['launchMS']),
            });
        }
    }, [launches]);

    const openLaunch = (launch) => {
        if (launch['al-state'] === 'upcoming') return;
        if (launch['al-state'] === 'current' && launch['link']) {
            window.open(launch['link'], '_blank');
            return;
        }

        setLaunchModal({ launch, open: true });
    };

    const getAllocation = (launch) => {
        const allocations = user?.allocations || [];
        const allocation = allocations.find((a) => launch.title.includes(a.pledge.name));
        if (allocation) return allocation;
        else return { allocation: 0 };
    };

    const gotoPledge = (ev) => {
        ev.stopPropagation();
        navigate(`/pledge`);
    };

    const renderLaunches = (type) => {
        if (_launches?.[type]?.length === 0)
            return (
                <div className={classes.emptyContainer}>
                    <Typography variant="body1">There are no {type} Launches at this time.</Typography>
                </div>
            );
        else
            return (
                <Grid container alignItems="flex-start" alignContent="center">
                    {_launches[type]?.map?.((d) => {
                        let gridSizes = {
                            xs: 12,
                            sm: 6,
                            md: 4,
                            lg: 3,
                        };

                        return (
                            <Grid
                                key={d.title + ' Launch'}
                                item
                                {...gridSizes}
                                onClick={() => openLaunch(d)}
                                className={clsx(classes.launchContainer, {
                                    [classes.pointerCursor]: type !== 'upcoming',
                                })}
                            >
                                <img src={buildImageURL(d['al-cardimage'])} className={classes.launchImage} alt="launch" />
                                <div className={classes.launchDescription}>
                                    <Typography variant="h3">
                                        {d.title}
                                        {d['al-type'] && ` - ${d['al-type']}`}
                                    </Typography>
                                    {type !== 'upcoming' && <Typography variant="h4">{d['al-totalvalue']} Total Value</Typography>}
                                    {type === 'upcoming' && (
                                        <div>
                                            <Typography variant="h5" className={classes.normalText}>
                                                Time until Launch:
                                            </Typography>
                                            <DayCountdown end={d['launchMS']} />
                                        </div>
                                    )}
                                    {type === 'current' && d['al-enddate'] && (
                                        <DayCountdown suffix="Remaining" end={Number(moment.utc(d['al-enddate']).format('x'))} />
                                    )}
                                    {type === 'current' &&
                                        getAllocation(d).allocation.allocation > 0 &&
                                        getAllocation(d).pledge.isActive && (
                                            <Button
                                                fullWidth
                                                size="small"
                                                onClick={gotoPledge}
                                                override="translucent"
                                                classes={{ root: classes.button }}
                                            >
                                                Pledge Now (Max: ${Math.floor(getAllocation(d).allocation.allocation)})
                                            </Button>
                                        )}
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            );
    };

    return (
        <React.Fragment>
            <Background />
            <Meta title="Axion | Launch" />
            <Page classes={{ inner: classes.inner }} header={<PageTitle />}>
                <div className={classes.currentUpcomingContainer}>
                    {_launches.current.length > 0 && (
                        <div className={classes.upcomingContainer}>
                            <Typography variant="h2" className={classes.launchTitle}>
                                Current Launches
                            </Typography>
                            {renderLaunches('current')}
                        </div>
                    )}
                    {_launches.upcoming.length > 0 && (
                        <div className={classes.upcomingContainer}>
                            <Typography variant="h2" className={classes.launchTitle}>
                                Upcoming Launches
                            </Typography>
                            {renderLaunches('upcoming')}
                        </div>
                    )}
                </div>

                <div className={classes.section}>
                    <Typography variant="h2" className={classes.launchTitle}>
                        Past Launches
                    </Typography>
                    {renderLaunches('past')}
                </div>
            </Page>

            <LaunchModal
                open={launchModal.open}
                launch={launchModal.launch}
                onClose={() => setLaunchModal({ ...launchModal, open: false })}
            />
        </React.Fragment>
    );
};

export default Launch;
