import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    confirmIcon: {
        fontSize: 20,
        color: theme.brand.teal,
    },
    root: {
        zIndex: '5 !important',
        [theme.breakpoints.down('md')]: {
            marginTop: '70px',
        },
    },
    paper: {
        backgroundColor: theme.brand.CardBG,
    },
    info: {
        color: theme.mode === 'dark' ? 'white' : theme.brand.axion,
    },
    warning: {
        color: theme.mode === 'dark' ? 'white' : theme.brand.orange,
    },
    success: {
        color: theme.mode === 'dark' ? 'white' : theme.brand.JavaGreen,
    },
    dialogTitle: {
        padding: theme.spacing(2),
        textTransform: 'none',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    actions: {
        padding: '2% 5%',
        display: 'flex',
        justifyContent: 'space-between',

        [theme.breakpoints.down('xs')]: {
            borderTop: '1px solid rgba(0,0,0,0.5)',
        },
    },
    explainItem: {
        marginTop: 20,
    },
    descriptionItem: {
        marginBottom: theme.spacing(2),
    },
    textLink: {
        cursor: 'pointer',
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    eligible: {
        color: theme.brand.JavaGreen,
    },
    ineligible: {
        color: theme.brand.error,
    },
}));

export default useStyles;
