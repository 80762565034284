import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootSaga from './config.saga';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(
    {
        key: 'root',
        storage: storage,
    },
    reducers,
);

// export const store = createStore(persistedReducer, {}, enhancer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});
export const persistor = persistStore(store);

async function shouldClearLocalStorage() {
    const clear = '22-clear';
    let value = localStorage.getItem(clear);
    if (!value) {
        persistor.purge();
        localStorage.clear();
        document.cookie.split(';').forEach((c) => {
            document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
        });

        localStorage.setItem(clear, 'done');
    }
}
shouldClearLocalStorage();
sagaMiddleware.run(rootSaga);
