import React from 'react';
import moment from 'moment';
import useStyles from './styles';
import { openBlock } from 'utils/open-etherscan';
import { Typography, Grid } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';

const VoteInformation = ({ proposal, results }) => {
    const classes = useStyles();
    const information = [
        {
            key: 'Start',
            value: moment.unix(proposal.start).format('MMM DD, YYYY - h:mm A'),
        },
        {
            key: 'End',
            value: moment.unix(proposal.end).format('MMM DD, YYYY - h:mm A'),
        },
        {
            key: 'Block',
            value: proposal.snapshot.toLocaleString(),
            extra: <OpenInNewOutlined className={classes.open} onClick={() => openBlock(proposal.snapshot)} />,
        },
        {
            key: 'Status',
            value: Date.now() / 1000 > proposal.end ? 'Complete' : Date.now() / 1000 < proposal.start ? 'Starting Soon' : 'In Progress',
        },
    ];

    return (
        <Grid container justify="space-between" alignItems="center" className={classes.infoGrid}>
            {information.map((i, index) => (
                <React.Fragment key={`some-${index}`}>
                    <Grid item xs={2}>
                        <Typography variant="h4">{i.key}:</Typography>
                    </Grid>
                    <Grid item container xs={10} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Typography align="right" className={classes.value}>
                                {i.value}
                            </Typography>
                        </Grid>
                        {i?.extra && (
                            <Grid item>
                                <Typography>&nbsp;{i.extra}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
};

export default VoteInformation;
