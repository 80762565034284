import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: '3%',
    },
    launchTitle: {
        marginBottom: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        borderBottom: `2px solid ${theme.brand.border}`,
    },
    launchContainer: {
        padding: theme.spacing(2),
    },
    normalText: {
        textTransform: 'none',
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    launchImage: {
        width: '100%',
        borderRadius: 10,
        marginBottom: theme.spacing(1),
    },
    emptyContainer: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        paddingBottom: theme.spacing(7),
    },
    launchDescription: {
        paddingLeft: theme.spacing(1),
    },
    connect: {
        textAlign: 'center',
        paddingTop: theme.spacing(5),
    },
}));

export default useStyles;
