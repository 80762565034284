import { MenuItem, Select, TextField, Typography } from '@mui/material';
import React from 'react';
import { themes } from 'theme';
import useStyles from './styles';
import { setTheme } from 'redux/actions/theme';
import { useDispatch, useSelector } from 'react-redux';

const ThemeSwitcher = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { theme } = useSelector((store) => store);

    const handleTheme = (e) => {
        const newTheme = e.target.value;
        dispatch(setTheme(newTheme));

        if (newTheme === 'dark' && !localStorage.getItem('prefersDark')) localStorage.setItem('prefersDark', 1);
    };

    return (
        <React.Fragment>
            <Select
                fullWidth
                margin="dense"
                label="Theme"
                variant="outlined"
                inputProps={{ className: classes.selectText }}
                value={theme.mode}
                onChange={handleTheme}
                MenuProps={{
                    disableScrollLock: true,
                    classes: { paper: classes.selectMenu },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
            >
                {themes.map((t, idx) => (
                    <MenuItem key={idx} value={t.id} className={classes.selectMenuItem}>
                        {t.name}
                    </MenuItem>
                ))}
            </Select>
        </React.Fragment>
    );
};

export default ThemeSwitcher;
