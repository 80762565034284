import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    confirmIcon: {
        fontSize: 20,
        color: theme.brand.teal,
    },
    paper: {
        backgroundColor: theme.brand.CardBG,
    },
    info: {
        color: theme.mode === 'dark' ? 'white' : theme.brand.axion,
    },
    warning: {
        color: theme.mode === 'dark' ? 'white' : theme.brand.orange,
    },
    success: {
        color: theme.mode === 'dark' ? 'white' : theme.brand.JavaGreen,
    },
    dialogTitle: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        textTransform: 'none',
        justifyContent: 'space-between',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    walletLogo: {
        marginBottom: 10,
        width: 45,
        height: 45,
    },
    subTitle: {
        marginTop: 5,
        fontSize: '0.9em',
        fontWeight: 300,
    },
    walletConnectButton: {
        textAlign: 'center',
        borderRadius: 5,
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.5)',
        padding: '10%',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',

        '&:hover': {
            backgroundColor: `${theme.brand.accent}1e`,
        },
    },
    closeIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: 'rotate(-90deg)',
            transition: 'transform 0.3s ease',
        },
    },
    contentContainer: {
        marginBottom: '3%',
    },
    loadingIndicator: {
        padding: '18% 0',
    },
}));

export default useStyles;
