import React from 'react';
import { withStyles } from '@mui/styles';

import styles from './styles';

const HorizontalCell = (props) => {
    const { classes } = props;

    return <div className={classes.root}>{props.children}</div>;
};

export default withStyles(styles)(HorizontalCell);
